import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
//import Celestial from 'd3-celestial'
import '../../App.css'
import Geosuggest from 'react-geosuggest';
import FileBase64 from './fileBase64';
import moment from 'moment'
import {store} from '../../_reducers/store'
import { cartConstants } from '../../_constants';
import ReactTooltip from 'react-tooltip';
import Cropper from './cropper'
import axios from 'axios'
import config from '../../config'
import Recorder from './recordAudio'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const notify = (e) => toast.error(e, {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });
const fonts = [
    'Oswald',
    'Lobster',
    //'Mansalva',
    //"Permanent Marker",
    'Pacifico',
    'Barriecito',
    'Sacramento',
    //'Lacquer',
    //'"Fredericka the Great"',
    //`'Balsamiq Sans', cursive`,
    `'Dancing Script', cursive`,
    //`'Londrina Solid', cursive`,
    `'Courgette', cursive`,
    `'Advent Pro', sans-serif`
]

const directDownload = async (props)=>{
    console.log(props.state,props.state._id)
    var {data} = await axios.post(config.apiUri+'/PriceCalc/directDownload', {
        items:[props.state],
        _id:props.state._id
    })
    console.log(data)
    window.open(config.apiUri+`/PriceCalc/downloadImg/${data._id}/0`)
}

const redirectPayment = async (props, onUpdate, next)=>{
    if(!props.state.buyerName){
        return notify('Lütfen Ad Soyad Kısmını Boş Bırakmayın')
    }
    next()
    const whichpage = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      }).type
    try {
        
        var {data} = await axios.post(config.apiUri+'/PriceCalc/directDownload', {
            items:[{...props.state, size: whichpage === 'music'? 'tp' : props.state.size}]
        })
        
        if(!data._id){
            onUpdate({target:{name:"currentStage", value:props.state.currentStage-1}})
            return notify('Bilinmeyen Hata, Lütfen tekrar deneyin.')
        }
        //window.open(config.apiUri+`/PriceCalc/downloadImg/${data._id}/0`)
        
        var {size,hediyePaketi,currentStage,biralanabirhediye, ahsapstand, plastikstand} = props.state;

        //alert(`${ahsapstand} ${plastikstand}`)
       
        if(whichpage === 'music') {
            size = 'tp'
            document.getElementById('option0').value = 'Özel Hediye Paketleme'
            document.getElementById('opsin1').value = 'Ahsap Stand'
            document.getElementById('opsin2').value = 'Plastik stand'
            
            document.getElementById('plasticstand').value=plastikstand ? 'Plastik stand' : ''
            document.getElementById('hediyepaketi').value=hediyePaketi ? 'Özel Hediye Paketleme' : ''
            document.getElementById('ikinci').value=ahsapstand ? 'Ahsap Stand': ''
        }else {
            document.getElementById('hediyepaketi').value=hediyePaketi&&size!='img'?'Hediye Paketleme':''
            document.getElementById('option0').value = 'Hediye Paketleme'
            document.getElementById('ikinci').value=biralanabirhediye&&size=='10x15'?'İkinci Ürün':''
        }
        var ids = {
            "13x18":{productId:3892186, id2:3455439, id:143351},
            "10x15":{productId:3892757, id2:3456010, id:143351},
            "25x34":{productId:3892579, id2:3455832, id:143351},
            "34x44":{productId:3892597, id2:3455850, id:143351},
            "tp":{productId:10958657, id2:10521910, id:143351},
            "img":{productId:5519351, id2:5082604, id:143351},
        }
        /*
        <option value='10x15'>10x15 Çerçeveli (75,00 ₺)</option>
        <option value='13x18'>13x18 Çerçeveli (85,00 ₺)</option>
        
        <option value='25x34'>25x34 Çerçeveli (105,00 ₺)</option>
        <option value='34x44'>34x44 Çerçeveli (115,00 ₺)</option>
        
        */
        var vals = ids[size]
        //alert(size)
        document.getElementById('productId').value=vals.productId
        document.getElementById('productId2').value=vals.id2
        //document.getElementById('PVG').value=vals.id
        if(biralanabirhediye){
            document.getElementById('productId').value=3892757
            document.getElementById('productId2').value=3456010
        }
        
        //var win = window.open(`https://www.shopier.com/ShowProductNew/products.php?id=${vals.productId}`, '_blank');
        //win.focus();
    
        document.getElementById('cartForm').submit()
        
        window.$(document).ready(function(){
            /*var loginform = document.getElementById("cartForm");
            loginform.style.display = "none";
            loginform.submit();
            //console.log($('#frameModal'))
            //document.getElementById("frameModal").style.display = ''
            window.$('#frameModal').modal('show');*/
        });
    }catch(e){
        console.log(e)
        onUpdate({target:{name:"currentStage", value:props.state.currentStage-1}})
        return notify('Bilinmeyen Hata, Lütfen bizimle iletişime geçin.')
    }
    
}
class Stage1 extends React.Component{
  constructor(props){
    super(props);
    this.state = {...props.state,
        ustNot:'Kendim Yazacağım',
        ustNot2:'Kendim Yazacağım',
        }
    }

    componentDidMount(){

    }

  

    onUpdate = (e)=>{
        var u = {}
        if(e.target.type === 'checkbox'){
            u[e.target.name] = e.target.checked
        }else {
            u[e.target.name] = e.target.value
        }
    
        this.props.onUpdate(u)
    }

  render(){
    var {topTitle,secondTopTitle,subTitle,subTitle2,currentStage,font,buyerName} = this.props.state; 
    var {ustNot2, ustNot} = this.state;
    
    const ustNotChange = (val)=>{
        if(val=='Kendim Yazacağım'){
            this.setState({ustNot:val})
            this.onUpdate({target:{name:"subTitle", value:''}})
        }
        if(val=='Üst not istemiyorum'){
            this.setState({ustNot:val})
            this.onUpdate({target:{name:"subTitle", value:' '}})
            return;
        }
        if(val!='Kendim Yazacağım'){
            this.setState({ustNot:val})
            this.onUpdate({target:{name:"subTitle", value:val}})
        }
    }
    const ustNot2Change = (val)=>{
        if(val=='Kendim Yazacağım'){
            this.setState({ustNot2:val})
            this.onUpdate({target:{name:"subTitle2", value:''}})
        }
        if(val=='Alt Not İstemiyorum'){
            this.setState({ustNot2:val})
            this.onUpdate({target:{name:"subTitle2", value:' '}})
            return;
        }
        if(val!='Kendim Yazacağım'){
            this.setState({ustNot2:val})
            this.onUpdate({target:{name:"subTitle2", value:val}})
        }
    }
    const whichpage = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      }).type
    return (
        <div style={{minHeight:'300px',marginLeft:'auto',marginRight:'auto', padding:'0px', position:'relative'}} className="card col-sm-12 bgshadow">
            <ReactTooltip />
            <ToastContainer />
            <div className="card-header">
                <h4 className="text-center font-weight-bold">Başlıkları Özelleştirin</h4>
            </div>
            <div className="card-body">
                
                <div  style={{padding:'0px'}} className="col-sm-12">
                    {/*<div className="card-header">
                        Başlıklar <i data-tip="Posterde görünmesini istediğiniz mesajları ve yazı tipini belirleyin." className="fas fa-exclamation-circle"></i>
                    </div>*/}
                    <div className="card-body">
                        <h5 class="title1">{whichpage !== 'music' ? 'Tarih/Üst Not' : 'Şarkı Adı'}</h5>
                        <input onChange={this.onUpdate} type="text" name="topTitle" maxlength={30} value={topTitle} className="form-control" placeholder="Üst Başlık"/>
                        {/*<h5 class="title1">Üst Not</h5>
                        <select value={ustNot} onChange={(e)=>{ustNotChange(e.target.selectedOptions[0].innerHTML)}} className="form-control">
                            <option>Lütfen Seçiniz</option>
                            <option>Kendim Yazacağım</option>
                            <option>Üst not istemiyorum</option>
                            <option>DOĞDUĞUNDA YILDIZLAR BÖYLEYDİ</option>
                            <option>DOĞDUĞUNDA YILDIZLAR BÖYLE DİZİLMİŞTİ</option>
                            <option>DÜNYAYA GÖZÜNÜ AÇTIĞIN GÜN YILDIZLAR BÖYLEYDİ</option>
                            <option>TANIŞTIĞIMIZDA YILDIZLAR BÖYLE DİZİLMİŞTİ</option>
                            <option>SENİ İLK GÖRDÜĞÜMDE YILDIZLAR BÖYLEYDİ</option>
                            <option>BANA "EVET" DEDİĞİN GÜN YILDIZLAR BÖYLEYDİ</option>
                        </select>
                {ustNot=='Kendim Yazacağım'?<input  maxlength={44} onChange={this.onUpdate} type="text" name="subTitle" value={subTitle} className="form-control" placeholder="Alt Başlık"/>:null*/}
                        <h5 class="title1">{whichpage !== 'music' ? 'Alt Not' : 'Sanatçı Adı'}</h5>
                        {whichpage !== 'music' && <select value={ustNot2} onChange={(e)=>{ustNot2Change(e.target.selectedOptions[0].innerHTML)}} className="form-control" >
                            <option>Kendim Yazacağım</option>
                            <option>Alt Not İstemiyorum</option>
                            <option>O kadar güzel bir şiirsin ki, Şaiirin ölür sen ölmezsin.</option>
                            <option>O kadar güzelsin ki, Sana düşmenin uçurumdan farkı yok ❤️</option>
                            <option>Sen Benim Şarkılarımsın.</option>
                            <option>Daima Solumda ve Soluğumda</option>
                        </select>}
                        {ustNot2=='Kendim Yazacağım'?<textarea onChange={this.onUpdate} style={{margin:'0px', marginBottom:'10px'}} type="text" name="subTitle2" value={subTitle2} className="form-control" placeholder="Alt Başlık"></textarea>:null}
                       
                        {whichpage !== 'music' && <h5 class="title1">Başlık için font seçin</h5>}
                        {whichpage !== 'music' && <select  className="form-control" style={{fontFamily:font}} onChange={(e)=>{this.onUpdate({target:{name:"font", value:e.target.value}}) }} value={font}>
                                 
                                {fonts.map((f,i)=><option key={i} style={{fontFamily:f, cursor:'pointer'}} value={f}>{f}</option> )}
                            </select>}
                      {/*<input onChange={this.onUpdate} type="text" name="secondTopTitle" value={secondTopTitle} className="form-control" placeholder="2. Üst Başlık"/>*/}
                        <h5 class="title1" style={{fontSize:'13px'}}>Alıcı Bilgileri</h5>
                        <input onChange={this.onUpdate} type="text" name="buyerName" maxlength={30} value={buyerName} className="form-control" placeholder="Alıcı İsim ve Soyisim"/>
                        <h5 style={{fontWeight:600, color:'#c10000', fontSize:'15px'}}>NOT: Lütfen bir sonraki aşamaya da BURADA YAZDIGINIZ ALICI İSMİNİ VE ADRESİNİ GİRİNİZ.  </h5>
                    </div>
                   
                    
                    <div className="text-center" style={{padding:'10px 10px 0px 10px'}}>
                            
                            {/**
                             <label style={{marginLeft:'30px', width:"40%", fontFamily:f, cursor:'pointer'}} onClick={()=>{this.onUpdate({target:{name:"font", value:f}})}} className="form-check-label">
                                    <input style={{cursor:'pointer'}} type="checkbox" className="form-check-input" checked={f==font}/>{f}
                                </label>
                             */}
                    </div>
                    
                    
                </div>
                
                
                <div style={{display:'flex'}} className="col-sm-12">
                        <button className="btn btn-primary btn-sm col-sm-5 col-5" onClick={()=>{this.onUpdate({target:{name:"currentStage", value:currentStage-1}})}}>Önceki Adım</button>
                        {this.props.directDownload?<button className="btn btn-success btn-sm col-sm-5 col-5" style={{marginLeft:'auto'}} 
                            onClick={()=>{directDownload(this.props)}}>indir</button>
                        :
                        <button className="btn btn-success btn-sm col-sm-5 col-5" style={{marginLeft:'auto'}} onClick={()=>{redirectPayment(this.props, this.onUpdate, ()=>this.onUpdate({target:{name:"currentStage", value:currentStage+1}}))/*store.dispatch({type:cartConstants.ADD, item:this.props.state})*/}}>Ödeme</button>
                            
                            }
                    
                    </div>
            </div>
        </div>
      ); 
  }
  
}

class Stage2 extends React.Component{
    constructor(props){
      super(props);
      this.state = {
            ...props.state,
            time:'12:00',
            showHour:false
        }
      this.geosuggestEl = React.createRef();
    }
  
    componentDidMount(){
  
    }
  
    onUpdate = (e)=>{
      var u = {}
      if(e.target.type == 'checkbox'){
          u[e.target.name] = e.target.checked
      }else {
          u[e.target.name] = e.target.value
      }
     
      this.props.onUpdate(u)
    }
    
    onDateChange = (date)=>{
        var {time} = this.state;
        console.log(`${date}T${time}`)
        this.onUpdate({target:{name:"date", value:`${date}T${time}`}})
    }


    render(){
        const geosuggestEl = null;
      var {showDate, date,currentStage, base64image,closeCropper} = this.props.state; 
      var {time, showHour} = this.state;
      console.log(showHour)
      return (
          <div style={{minHeight:'300px',marginLeft:'auto',marginRight:'auto', padding:'0px', position:'relative'}} className="card col-sm-12 bgshadow">
              <ReactTooltip />
              
              <div className="card-header">
                  <h4 className="text-center font-weight-bold">Tarihi ve Konumu Seçin</h4>
              </div>
              <div className="card-body">
                    
                  <div style={{padding:'0px'}} className="col-sm-12">
                        
                        {/*<div className="card-header">
                            Tarih ve Konum <i data-tip="Tarih ve konumu seçtiğinizde o ana ait gökyüzü modellenir, değişikliği kenardaki haritada gözlemleyebilirsiniz." className="fas fa-exclamation-circle"></i>
                        </div>*/}
                        
                       {<div>
                            <div className="form-check" style={{height:'1.5rem', marginTop:'20px'}}>
                            
                                <input className="form-check-input" onClick={this.onUpdate} checked={showDate?'checked':''} type="checkbox" id="scales" name="showDate" />
                                
                            </div>
                       </div> /*type="datetime-local"
                       moment(date).format('YYYY-MM-DDTHH:mm')*/}
                        <label className="form-check-label title1" for="scales" style={{marginTop:'10px', marginBottom:'10px'}}>Tarih </label>
                        <input type="date" onChange={(e)=>{this.onDateChange(e.target.value)}} name="date" value={moment(date).format('YYYY-MM-DD')} style={{width:'fit-content'}}  className="form-control"></input>
                        
                        <label className="form-check-label title1" for="scales" style={{marginTop:'10px', marginBottom:'10px'}}>Saat <input type='checkbox' checked={showHour} onChange={(e)=>{this.setState({showHour:e.target.checked});if(e.target.checked==false){this.setState({time:'12:00'}, ()=>{this.onDateChange(moment(date).format('YYYY-MM-DD'))})}}}></input></label>
                        {showHour?<input type="time" onChange={(e)=>{console.log(e.target.value);this.setState({time:e.target.value}, ()=>{this.onDateChange(moment(date).format('YYYY-MM-DD'))})}} value={time} style={{width:'fit-content'}}  className="form-control"></input>:null}

                        <h5 class="title1">Gökyüzünü Nereden Görmek İstiyorsunuz?</h5>
                        <Geosuggest ref={this.geosuggestEl} onSuggestSelect={(suggest)=>{
                                console.log(suggest)
                                //console.log(suggest.gmaps.formatted_address)
                                
                                
                                if(suggest){
                                    var text = ''
                                    if(suggest.gmaps){
                                        var sparr = suggest.gmaps.formatted_address.split(' ');
                                        var arr = suggest.gmaps.formatted_address.split(' ').filter(x=>x.includes('/'))//[0].replace(',','')
                                        if(arr[0]){
                                            text = arr[0].replace(',','')
                                        }
                                        if(text==''){
                                            text=`${sparr[sparr.length-2]} ${sparr[sparr.length-1]}`
                                        }
                                    }
                                    
                                    this.onUpdate({target:{name:"location", value:suggest.location}})
                                    this.onUpdate({target:{name:"locationText", value:text}})
                                    //console.log(suggest)
                                
                                }}} placeholder={'Konum'} />
                       
                        
                    </div>
                    <div style={{display:'flex', marginTop:'91px'}} className="col-sm-12">
                        <button className="btn btn-primary btn-sm col-sm-5 col-5" onClick={()=>{this.onUpdate({target:{name:"currentStage", value:currentStage-1}})}}>Önceki Adım</button>
                        <button className="btn btn-primary btn-sm col-sm-5 col-5" style={{marginLeft:'auto'}} onClick={()=>{this.onUpdate({target:{name:"currentStage", value:currentStage+1}})}}>Sonraki Adım</button>
                    </div>
              </div>
          </div>
        ); 
    }
    
  }
  class Stage3 extends React.Component{
    constructor(props){
      super(props);
      this.state = props.state
      this.geosuggestEl = React.createRef();
    }
  
    componentDidMount(){
  
    }
  
    onUpdate = (e)=>{
      var u = {}
      if(e.target.type == 'checkbox'){
          u[e.target.name] = e.target.checked
      }else {
          u[e.target.name] = e.target.value
      }
      if(e.target.name == 'biralanabirhediye'){
        this.onUpdate({target:{name:"size", value:'10x15'}}) 
      }
      this.props.onUpdate(u)
    }
    getFiles = (file)=>{
        //alert("Lütfen ışık ve görüntü kalitesi en güzel fotoğrafınızı seçin. Aksi taktirde baskınız karanlık çıkabilir.")
        this.onUpdate({target:{name:"base64image", value:file.base64}})
        var image = new Image();
        if(!window.bgPictures){
            window.bgPictures={}
        }
        image.onload = function() {
            window.bgPictures["celestial-map1"]=image
        };
        //console.log(window.bgpicturetodraw)
        image.src = file.base64
    }
    getFiles = (file)=>{
        this.onUpdate({target:{name:"base64image", value:file.base64}})
        this.onUpdate({target:{name:"closeCropper", value:false}})
        
        var image = new Image();
       //console.log(file.file.size)
       //console.log(file)
        if(file.file.size<500000){
            //alert('Lütfen Dikkat Edin! Baskı kalitesinin detaylı olması için daha yüksek çözünürlüklü bir fotoğraf seçmenizi tavsiye ederiz!')
        }
        if(!window.bgPictures){
            window.bgPictures={}
        }
        image.onload = function() {
            window.bgPictures["celestial-map1"]=image
        };
        //console.log(window.bgpicturetodraw)
        image.src = file.base64
    }
    removeImage = ()=>{
        this.onUpdate({target:{name:"base64image", value:null}})
        this.onUpdate({target:{name:"closeCropper", value:{}}})
        window.bgPictures["celestial-map1"]=null
    }
    render(){
        const whichpage = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
          }).type
        const geosuggestEl = null;
      var {showDate, date,currentStage,mask,size, ahsapstand, plastikstand, hediyePaketi,biralanabirhediye, secretmsg,base64image, closeCropper, eksen} = this.props.state; 
     //console.log(showDate)
      return (
          <div style={{minHeight:'300px',marginLeft:'auto',marginRight:'auto', padding:'0px'}} className="card col-sm-12 bgshadow">
              <ReactTooltip />
              {/*base64image&&!closeCropper?<Cropper image={base64image} onUpdate={this.onUpdate}></Cropper>:null*/}
              <div className="card-header">
                  <h4 className="text-center font-weight-bold">Tasarımı Özelleştirin</h4>
              </div>
              
              <div className="card-body">
                    
                  <div style={{}} className="col-sm-12">
                        {/*<label className="form-check-label title1" for="scales" style={{marginTop:'10px', marginBottom:'10px'}}>Eksen Çizgileri<input type='checkbox' checked={eksen} onChange={(e)=>{this.onUpdate({target:{name:"eksen", value:e.target.checked}});}}></input></label>*/}
                        <div style={{padding:'0px'}} className="col-sm-12">
                      
                      <h5 class="title1">Boyut</h5>
                     <div className="text-center" style={{padding:'10px 10px 0px 10px'}}>
                         {whichpage !== "music" ? <select  className="form-control" onChange={(e)=>{this.onUpdate({target:{name:"size", value:e.target.value}}) }} value={size}>
                            <option value='10x15'>10x15 Tablo 149.00₺ yerine 139.00₺</option>
                             <option value='13x18'>13x18 Tablo 169.00₺ yerine 159.00₺  </option>
                             
                             <option value='25x34'>21x30 Tablo 199.00₺ yerine 189.00₺</option>
                             <option value='34x44'>30X40 Tablo 229.00₺ yerine 209.00₺</option>
                          
                         </select> : <select  className="form-control" onChange={(e)=>{this.onUpdate({target:{name:"size", value:e.target.value}}) }} value={size}>
                            
                             <option value='tp'>13x18cm Dijital Plak 189.00₺ yerine 159.00₺</option>
                          
                         </select>}
                     </div>
                 </div>
                 {size!='img'?<div>
                         <h5 class="title1">Hediye paketleme 17.90₺<input style={{marginLeft:'10px'}} onClick={this.onUpdate} checked={hediyePaketi?'checked':''} type="checkbox" id="scales" name="hediyePaketi" /></h5>
                 </div>:null}
                 {whichpage==='music' && <div style={{display: 'flex'}}>
                    <h5 class="title1" style={{display: 'flex'}}>Ahşap Ayaklık 14.90₺<input style={{marginLeft:'10px'}} onClick={this.onUpdate} checked={ahsapstand?'checked':''} type="checkbox" id="scales" name="ahsapstand"/></h5>
                    <h5 class="title1 ml-3" style={{display: 'flex'}}>Gökyüzü temalı istiyorum 39,90₺<input style={{marginLeft:'10px'}} onClick={this.onUpdate} checked={plastikstand?'checked':''} type="checkbox" id="scales" name="plastikstand" /></h5>
                 </div>}
                 {size=='10x15'?<div>
                         <h5 class="title1">İkincisi %50 İndirimli Kampanyasından Faydalanmak İstiyorum (+37,50₺)<input style={{marginLeft:'10px'}} onClick={this.onUpdate} checked={biralanabirhediye?'checked':''} type="checkbox" id="scales" name="biralanabirhediye" /></h5>
                 
                 </div>:null}
                        {whichpage !== "music" && <h5 class="title1">Tasarım</h5>}
                        <div className="text-center" style={{display:'flex', flexWrap:'wrap'}}>
                            
                            {(whichpage !== "music" ? [
                                {mask:'canvas', img:"./images/mostbasic.png"},
                                {mask:'canvasStars', img:"./images/starsBG.JPG"},
                                //{mask:'half_moon', img:"./images/half-moonpreview.jpg"},
                                {mask:'canvasClouds', img:"./images/cloudsPreview.jpg"},
                                {mask:'tarla', img:"./images/tarlapreview.jpg"},
                                //{mask:'canvasWhite', img:"./images/whiteone.png"},
                                {mask:'mask_heart', img:"./images/heart.png"},
                                //{mask:'transparent_gift', img:"./images/heart.png"},
                                //{mask:'mask_star', img:"./images/star.png"},
                            ] : [//{mask:'transparent_gift', img:"./images/heart.png"},
                            //{mask:'mask_star', img:"./images/star.png"},
                            ]).map(x=> <div className="col-6 col-md-4 col-lg-4 mg-auto"><div onClick={()=>{this.onUpdate({target:{name:"mask", value:x.mask}})}} 
                                    className="maskSelect" style={{backgroundColor:mask==x.mask?'rgb(209 209 209)':'#fff'}}>
                                <img src={x.img} />
                                <label for="star" className="form-check-label"></label>
                            </div></div>	)}
                            
                            
                        </div>
                        
                    </div>
                    {whichpage !== "music" && <div>
                            <h5 className="title1 mt-5">Kare Fotoğraf<input style={{marginLeft:'10px', marginTop:'5px'}} onClick={this.onUpdate} checked={showDate?'checked':''} type="checkbox" id="scales" name="showDate" /></h5>
                    </div>}
                    {/*<h5 class="title1">Fotoğraf Ekleyin</h5>
                        <FileBase64
                                multiple={ false }
                                onDone={ this.getFiles } />
                        <img src={base64image} style={{width:'60px'}}></img>
                        <button onClick={()=>{this.removeImage()}}>DELETE</button>
                   
                    <div style={{padding:'0px'}} className="col-sm-12">
                      
                         <h5 class="title1">Boyut</h5>
                        <div className="text-center" style={{padding:'10px 10px 0px 10px'}}>
                            <select  className="form-control" onChange={(e)=>{this.onUpdate({target:{name:"size", value:e.target.value}}) }} value={size}>
                            
                                <option value='sm-c'>*34X44 Çerçeveli (94,90 ₺)</option>
                                <option value='m-c'>*25X34 Çerçeveli (74,90 ₺)</option>
                                <option value='img'>Kendim Bastıracağım HD Görüntü İstiyorum (34,90 ₺)</option>
                            </select>
                        </div>
                    </div>*/}
                    <div style={{display:'flex'}} className="col-sm-12">
                        {/*<button className="btn btn-primary btn-sm col-sm-5 col-5" onClick={()=>{this.onUpdate({target:{name:"currentStage", value:currentStage-1}})}}>Önceki Adım</button>*/}
                        <button className="btn btn-primary btn-sm col-sm-5 col-5" style={{marginLeft:'auto'}} onClick={()=>{this.onUpdate({target:{name:"currentStage", value:currentStage+1}})}}>Sonraki Adım</button>
                    </div>
              </div>
          </div>
        ); 
    }
    
  } 

  class Stage4 extends React.Component{
    constructor(props){
      super(props);
      this.state = {...props.state,
        selectedOption: 'link',
        link: 'block',
        yukle: 'none',
        video: 'none'
        };
      this.geosuggestEl = React.createRef();
    }
  
    componentDidMount(){
  
    }

    onValueChange = (e) => {
        this.state.selectedOption = e;
        if (this.state.selectedOption === "link") {
            this.state.link = "block";
            this.state.yukle = "none";
            this.state.video = "none"
        } else if (this.state.selectedOption === "yukle") {
            this.state.link = "none";
            this.state.yukle = "block";
            this.state.video = "none"
        } else {
            this.state.link = "none";
            this.state.yukle = "none";
            this.state.video = "block"
        }
        this.setState(this.state);
    }
  
    onUpdate = (e)=>{
      var u = {}
      if(e.target.type == 'checkbox'){
          u[e.target.name] = e.target.checked
      }else {
          u[e.target.name] = e.target.value
      }
     
      this.props.onUpdate(u)
    }
    
    getFiles = (file)=>{
        this.onUpdate({target:{name:"base64image", value:file.base64}})
        this.onUpdate({target:{name:"closeCropper", value:false}})
        
        /*var image = new Image();
       //console.log(file.file.size)
       //console.log(file)
        if(file.file.size<500000){
            //alert('Lütfen Dikkat Edin! Baskı kalitesinin detaylı olması için daha yüksek çözünürlüklü bir fotoğraf seçmenizi tavsiye ederiz!')
        }
        if(!window.bgPictures){
            window.bgPictures={}
        }
        image.onload = function() {
            window.bgPictures["celestial-map1"]=image
        };
        //console.log(window.bgpicturetodraw)
        image.src = file.base64*/
    }
    getSound = (file)=>{
        this.onUpdate({target:{name:"base64Sound", value:file.base64}})
    }
    removeImage = ()=>{
        this.onUpdate({target:{name:"base64image", value:null}})
        this.onUpdate({target:{name:"crop", value:null}})
    }

    directDownload = async ()=>{
        var {data} = await axios.post(config.apiUri+'/PriceCalc/directDownload', {
            items:[this.props.state]
        })
        console.log(data)
        window.open(config.apiUri+`/PriceCalc/downloadImg/${data._id}/0`)
    }

    /*redirectPayment = async ()=>{
        var {data} = await axios.post(config.apiUri+'/PriceCalc/directDownload', {
            items:[this.props.state]
        })
        console.log(data)
        window.open(config.apiUri+`/PriceCalc/downloadImg/${data._id}/0`)
        var {size} = this.props.state;
        var ids = {
            "13x18":{productId:3892186, id:2},
            "10x15":{productId:3892186, id:2},
            "25x34":{productId:3892186, id:2},
            "34x44":{productId:3892597, id:3455850},
            "img":{productId:5519351, id:3455850},
        }
        var vals = ids[size]
        document.getElementById('productId').value=vals.productId
        document.getElementById('productId2').value=vals.id
        
        window.$(document).ready(function(){
            var loginform = document.getElementById("cartForm");
            loginform.style.display = "none";
            loginform.submit();
            //console.log($('#frameModal'))
            //document.getElementById("frameModal").style.display = ''
            window.$('#frameModal').modal('show');
        });
    }*/

    directDownload = async ()=>{
        var {data} = await axios.post(config.apiUri+'/PriceCalc/directDownload', {
            items:[this.props.state]
        })
        console.log(data)
        window.open(config.apiUri+`/PriceCalc/downloadImg/${data._id}/0`)
    }

    /*redirectPayment = async ()=>{
        var {data} = await axios.post(config.apiUri+'/PriceCalc/directDownload', {
            items:[this.props.state]
        })
        console.log(data)
        window.open(config.apiUri+`/PriceCalc/downloadImg/${data._id}/0`)
        var {size} = this.props.state;
        var ids = {
            "13x18":{productId:3892186, id:2},
            "10x15":{productId:3892186, id:2},
            "25x34":{productId:3892186, id:2},
            "34x44":{productId:3892597, id:3455850},
            "img":{productId:5519351, id:3455850},
        }
        var vals = ids[size]
        document.getElementById('productId').value=vals.productId
        document.getElementById('productId2').value=vals.id
        
        window.$(document).ready(function(){
            var loginform = document.getElementById("cartForm");
            loginform.style.display = "none";
            loginform.submit();
            //console.log($('#frameModal'))
            //document.getElementById("frameModal").style.display = ''
            window.$('#frameModal').modal('show');
        });
    }*/

    render(){
        const geosuggestEl = null;
      var {showDate, date,filename,currentStage,mask,size,hediyePaketi, secretmsg,base64image, closeCropper,secretmsg,base64Sound} = this.props.state; 
      var {directDownload} = this.props
     //console.log(showDate)
        if(mask == `half_moon` && base64image){
            this.removeImage()
        }
        const whichpage = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
          }).type
      return (
          <div style={{minHeight:'300px',marginLeft:'auto',marginRight:'auto', padding:'0px'}} className="card col-sm-12 bgshadow">
              <ReactTooltip />
              {base64image&&!closeCropper?<Cropper image={base64image} onUpdate={this.onUpdate} cancel={()=>{this.removeImage()}}></Cropper>:null}
              <div className="card-header">
                  <h4 className="text-center font-weight-bold">Fotoğraf ve Boyut Seçin</h4>
              </div>
              <div className="card-body">
                
                    
                    <div style={{padding:'0px'}} className="col-sm-12">
                    {whichpage !== 'music' && <h5 style={{marginTop:'11px', marginBottom:'8px'}}>*Tablonuzda ses kaydınızla birlikte fon müzik çalmasını istiyorsanız, sesinizi kaydederken arka planda şarkınızı çalabilirsiniz. </h5>}
                        <div style={{marginBottom:'15px'}}>
                        {whichpage !== 'music' && <h5 style={{fontSize:'15px', marginLeft:'10px', marginTop:"13px"}}>Qr Kod Seçenekleri:</h5>}
                        {whichpage !== 'music' && <h5 style={{fontSize:'10px',  marginBottom:'13px', marginLeft:'10px',}}>*Not: Lütfen Seçeneklerden Yalnızca Birini Seçin </h5>}
                              {whichpage !== 'music' && <label style={{width: '100%'}}><input type="radio" value="link" checked={this.state.selectedOption === "link"} onChange={() => this.onValueChange("link")} /> Şarkı Linki İstiyorum</label>}
                              {whichpage !== 'music' && <label style={{width: '100%', marginTop:"-10px"}}><input type="radio" value="yukle" checked={this.state.selectedOption === "yukle"} onChange={() => this.onValueChange("yukle")} /> Ses Kaydım Olsun</label>}
                              {whichpage !== 'music' && <label style={{width: '100%'}}><input type="radio" value="video" checked={this.state.selectedOption === "video"} onChange={() => this.onValueChange("video")} /> Video Eklemek İstiyorum</label>}
                        </div>
                        <div>
                            <div style={{display: `${this.state.link}` }}>
                                <h5 class="title1">Şarkı linkini yapıştırın.(youtube vs)</h5>
                                <div className="text-center" style={{padding:'10px 10px 0px 10px'}}>
                                <input onChange={this.onUpdate} style={{margin:'0px', marginBottom:'10px'}} type="text" name="secretmsg" value={secretmsg} className="form-control" placeholder="Yönlendirme Linkini Yapıştırın..."/>
                                </div>
                            </div>

                            <div style={{display: `${this.state.yukle}` }}>
                                <FileBase64
                                    multiple={ false }
                                    onDone={ this.getSound }
                                    onName={ (filename)=>this.onUpdate({target:{name:"filename", value:filename}}) }
                                    alert={()=>{alert("*Tablonuzda ses kaydınızla birlikte Şarkı çalmasını istiyorsanız, Lütfen sesinizi kaydederken BAŞKA BİR CİHAZLA şarkınızı çalınız. Ses kaydınız tamamlandıktan sonra sesinize şarkı eklenmez.")}}
                                    format="audio/mp3,audio/aac,audio/*;capture=microphone;audio/mpeg4;audio/mpeg-4,.m4a,.aac"
                                    placeholder="Ses Kaydi Yüklemek İçin Tıklayın..."/>
                                    
                                
                                <h5 style={{marginTop:'11px', marginBottom:'11px'}}>Veya</h5>

                                <Recorder  onName={ (filename)=>this.onUpdate({target:{name:"filename", value:filename}}) } filename={filename} base64Sound={base64Sound} onUpdate={(val)=>this.onUpdate({target:{name:"base64Sound", value:val}})}></Recorder>
                                <h5 style={{marginTop:'11px', marginBottom:'30px'}}>*Eğer ses kaydı alamazsanız, lütfen farklı bir tarayıcıdan deneyin. Örneğin : Safari </h5>
                            </div>
                            <div style={{display: `${this.state.video}` }}>
                                <h5 style={{marginTop:'11px', marginBottom:'11px'}}>*Tablonuza Video Eklemek İstiyorsanız Lütfen Siparişinizi Verdikten Sonra WhatsApp Üzerinden İletişime Geçin </h5>
                                <h5 style={{marginTop:'11px', marginBottom:'11px'}}>Wp:0(551)1349238</h5>
                                {/*<a href="https://api.whatsapp.com/send?phone=+905511349238&text=Merhaba, Siparişime video eklemek istiyorum."><i style={{color:'#25D366', fontSize:'40px'}} class="fab fa-whatsapp-square"></i></a>*/}
                            
                            </div>
                        </div>
                    </div>
                    
                    {mask != `half_moon`?<h5 class="title1" style={{marginTop:'10px'}}>Kendi Fotoğrafınızı Ekleyin</h5>:null}
                        {mask != `half_moon`?<div style={{display:'flex', width:'100%'}}>
                            <FileBase64
                                multiple={ false }
                                alert={()=>{alert("Lütfen ışık ve görüntü kalitesi en güzel fotoğrafınızı seçin. Aksi taktirde baskınız karanlık çıkabilir.")}}
                                onDone={ this.getFiles } />
                            {base64image?<button type="button" class="btn btn-danger" onClick={()=>{this.removeImage()}}>Sil</button>:null}
                        </div>:null}
                        <img src={base64image} style={{width:'60px'}}></img>
                 
                    
                    <div style={{display:'flex'}} className="col-sm-12">
                        {<button className="btn btn-primary btn-sm col-sm-5 col-5" onClick={()=>{this.onUpdate({target:{name:"currentStage", value:currentStage-1}})}}>Önceki Adım</button>}
                        <button className="btn btn-primary btn-sm col-sm-5 col-5" style={{marginLeft:'auto', maxWidth:'50%'}} onClick={()=>{this.onUpdate({target:{name:"currentStage", value:currentStage+1}})}}>Sonraki Adım</button>
                    </div>
              </div>
          </div>
        ); 
    }
    
  } 



  class Stage5 extends React.Component{
    constructor(props){
      super(props);
      this.state = props.state
      this.geosuggestEl = React.createRef();
    }
  
    componentDidMount(){
  
    }

    render(){
        const geosuggestEl = null;
      var {showDate, date,currentStage,mask,size,hediyePaketi, secretmsg,base64image, closeCropper,secretmsg} = this.props.state; 
      var {directDownload} = this.props
     //console.log(showDate)
        if(mask == `half_moon` && base64image){
            this.removeImage()
        }
      return (
          <div style={{minHeight:'300px',marginLeft:'auto',marginRight:'auto', padding:'0px'}} className="card col-sm-12 bgshadow">
              <ReactTooltip />
              {base64image&&!closeCropper?<Cropper image={base64image} onUpdate={this.onUpdate}></Cropper>:null}
              <div className="card-header">
                  <h4 className="text-center font-weight-bold">Fotoğraf ve Boyut Seçin</h4>
              </div>
              <div className="card-body">
                <h5 style={{fontWeight:600,textAlign:"center", marginTop:'100px'}}>Siparişinizi hazırladınız. Lütfen yeni açılan sayfada ödemenizi tamamlayın. </h5> 
              </div>
          </div>
        ); 
    }
    
  } 
  class Stage4p5 extends React.Component{
    constructor(props){
      super(props);
      this.state = props.state
      this.geosuggestEl = React.createRef();
    }
  
    componentDidMount(){
  
    }

    render(){
        const geosuggestEl = null;
      var {showDate, date,currentStage,mask,size,hediyePaketi, secretmsg,base64image, closeCropper,secretmsg} = this.props.state; 
      var {directDownload} = this.props
     //console.log(showDate)
        if(mask == `half_moon` && base64image){
            this.removeImage()
        }
      return (
          <div style={{minHeight:'300px',marginLeft:'auto',marginRight:'auto', padding:'0px'}} className="card col-sm-12 bgshadow">
              <ReactTooltip />
              {base64image&&!closeCropper?<Cropper image={base64image} onUpdate={this.onUpdate}></Cropper>:null}
              <div className="card-body">
                <h5 style={{fontWeight:600,textAlign:"center", marginTop:'100px'}}>Siparişiniz hazırlanıyor lütfen bekleyin (Ses dosyanızın boyutuna göre bu işlem bir kaç dakika sürebilir. Lütfen sayfadan ayrılmayın! )... </h5> 
                <img style={{marginLeft:'45%', width:'10%'}} src="https://www.bluechipexterminating.com/wp-content/uploads/2020/02/loading-gif-png-5.gif"></img>
              </div>
          </div>
        ); 
    }
    
  } 
export default {
    Stage1 : Stage1,
    Stage2 : Stage2,
    Stage3 : Stage3,
    Stage4 : Stage4,
    Stage4p5:Stage4p5,
    Stage5 : Stage5
}