import { cartConstants } from '../_constants';
//localStorage.clear();
import config from '../config'
import history from '../_constants/history'
var items = JSON.parse(localStorage.getItem('cartItems'));

if(!items){
    items=[]
}
export function Cart(state = {items:items}, action) {
    switch (action.type) {
        case cartConstants.ADD:
            localStorage.setItem('cartItems', JSON.stringify([...state.items, action.item]));
            setTimeout(() => {
                history.push('/CheckOut')
                //window.location.replace(config.apiUri+"/CheckOut");
            }, 1000);
            
            return {
                //...state,
                items:[...state.items, action.item]
            };
        case cartConstants.REMOVE:
            var items = JSON.parse(JSON.stringify(state.items))
            items.splice(action.index, 1)
            
            localStorage.setItem('cartItems', JSON.stringify(items));
            return {
                items:items,
            };
        case cartConstants.CLEAR:
            localStorage.setItem('cartItems', JSON.stringify([]));
            return {
                items:[]
            };
        default:
        return state
    }
} 