import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import ProductPreview from '../components/productPreview'
import '../product.css';
import axios from 'axios'
import config from '../config'
import {store} from '../_reducers/store'
import { cartConstants } from '../_constants';
import {
  EmailShareButton,
  
  WhatsappShareButton
} from "react-share";


class CheckOut extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      /*product:{
        title:'',
        description:'',
        images:[],
        stock:0,
        choices:[//{name:'Renk', options:[]}
        
        ],
        detailedDescription:'',
        price:'',
        noteEnabled:false,
        keywords:[]
      },*/
      piece:1,
      choices:{},
      note:'',
      tabNumber:0,
      modalOpen:false
    }
  }
  
  componentDidMount(){
    this.getProduct()
  }

  modal = ()=>{
    var {modalOpen,_id} = this.state
    const urlParams = new URLSearchParams(window.location.search);
    var {title,description, choices,price,stock, noteEnabled,images, detailedDescription, isActive, keywords} = this.state.product
    const id = urlParams.get('Id');
    var url = config.apiUri+'/Product?Id='+id
    return (<div class="modal" tabindex="-1" role="dialog" style={{display:modalOpen?'block':'none'}}>
        <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title">Sevdikleriniz ile paylaşın</h5>
            <button onClick={()=>this.setState({modalOpen:false})} type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body" style={{textAlign:'center', paddingTop:'30px'}}>
            {/*<QRCode value={config.apiUrl+'/Program?id='+_id} />*/}
            <label style={{fontSize:'18px'}}>Paylaşma Seçenekleri</label><br/>
  
            <EmailShareButton url={url} subject={'yildizim.shop '+title} body={'yildizim.shop '+title+'\n'}><i class="far fa-envelope" style={{fontSize:'35px'}}></i></EmailShareButton>
            <WhatsappShareButton style={{ marginLeft:'20px'}} url={url} title={'yildizim.shop '+title} separator={'\n'}><i class="fab fa-whatsapp-square" style={{fontSize:'35px', color:'#075e54'}}></i></WhatsappShareButton>
            </div>
           
        </div>
        </div>
    </div>)
  }

  getProduct = async ()=>{
    var {dispatch} = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('Id');
    if(id){
      var {data} = await axios.get(config.apiUri+'/products/getProduct/'+id)
      if(data.err){
        return alert(data.err);
      }
      this.setState({product:data})
    }
  }

  addToCart = ()=>{
    var {_id } = this.state.product
    var {piece, note, choices,product} = this.state
    console.log({
      _id,
      piece,
      note,
      choices,
      type:'Generic'
    })
    store.dispatch({type:cartConstants.ADD, item:{
      _id,
      piece,
      note,
      choices,
      product,
      type:'Generic'
    }})
  }

  onUpdate = (e)=>{
    var u = {}
    if(e.target.type === 'checkbox'){
        u[e.target.name] = e.target.checked
    }else {
        u[e.target.name] = e.target.value
    }
    
    this.setState(u)
  }

  render(){
    
    
    var {piece, note,tabNumber} = this.state
    if(!this.state.product){
      return (<div  style={{backgroundColor:'rgb(255 255 254)', minHeight:'calc( 100vh - 149px)', paddingTop:'10px'}}>

      </div>)
    }
    var {title,description, choices,price,stock, noteEnabled,images, detailedDescription, isActive, keywords} = this.state.product

    return (
      <div  style={{backgroundColor:'rgb(255 255 254)', minHeight:'calc( 100vh - 149px)', paddingTop:'10px'}}>
        {this.modal()}
        <div className='container' style={{display:'flex', flexWrap:'wrap'}}>
          <div className="col-12 col-md-12" style={{display:'flex', flexWrap:'wrap'}}>
            {keywords.map(o=><a href={'/products?keywords='+o}><div className='' style={{border:'1px solid rgba(0,0,0,.125)'/*, backgroundColor:'rgb(0 128 0 / 73%)'*/, borderRadius:'.25rem',width:'fit-content', padding:'3px 11px 4px 11px',  fontSize:'13px',marginLeft:"3px", fontWeight:'600'}}>{o}</div></a>)}
          </div>
          <div className="col-12 col-md-6 col-lg-6" style={{marginBottom:'30px', padding:'0px'}}>
            <ProductPreview product={this.state.product} options={{hideDesc:true, height:'90%', imgList:true}}/>
          </div>
          <div className="col-12 col-md-6 col-lg-6" style={{position:'relative',marginBottom:'30px',}}>
            <div onClick={()=>this.setState({modalOpen:true})} style={{position:'absolute', right:'28px', top:'28px', cursor:'pointer' }}>
              <i class="fas fa-share-alt" style={{fontSize:'20px', color:'#00000075', float:'right'}}></i>
              <p style={{fontSize:'12px', fontWeight:"500", marginRight:'-8px'}}>Paylaş</p>
            </div>
            
            <h5 style={{paddingTop:'6%', paddingLeft:'2%', fontSize:'31px', fontWeight:'700'}}>{price?parseFloat(price).toFixed(2):'-'}₺</h5>
            
            <h5 style={{paddingTop:'0%', paddingLeft:'2%', fontSize:'22px', fontWeight:'600'}}>{title}</h5>
    {stock>=0?<h5 style={{paddingTop:'0%', paddingLeft:'2%', fontSize:'18px', fontWeight:'600', color:stock<5&&stock>0?'#ff0000e3':'#00000075'}}>Stok : {stock} {stock<5&&stock>0?'Tükenmek Üzere!':""}</h5>:null}
            <div className="col-12" style={{border:'1px solid rgba(0,0,0,.125)',borderRadius:'.25rem',paddingBottom:'15px'}}>
             {choices.map(c=>{
               return <div className="col-12" style={{padding:'0px', marginTop:'15px', display:'flex'}}>
                  
                  <div className="col-12" style={{display:'flex', flexWrap:'wrap', padding:'0px', marginTop:'0px'}}>
                  <h5 style={{paddingTop:'0%', paddingLeft:'2%', width:'fit-content', fontSize:'18px', fontWeight:'600', marginBottom:'0px', marginRight:'10px', paddingTop:'4px', color:'rgb(0 0 0 / 58%)'}}>{c.name}</h5>
                    {c.options.map(o=><div className='' onClick={()=>{
                        var {choices}=this.state;
                        choices[c.name] = o;
                        this.setState({choices})
                      }} style={{backgroundColor:this.state.choices[c.name]==o?'#ff4d4d40':'', border:'1px solid rgba(0,0,0,.125)',cursor:'pointer',borderRadius:'.25rem',width:'fit-content', padding:'3px 11px 4px 11px',  fontSize:'13px',marginLeft:"3px", fontWeight:'600'}}>{o}</div>)}
                  </div>
               </div>
             })}
              {noteEnabled?<div className="col-12" style={{ paddingLeft:'0px', marginTop:'15px', paddingRight:'0px'}}>
                <h5 style={{paddingTop:'0%', paddingLeft:'2%', fontSize:'18px', fontWeight:'600'}}></h5>
                <textarea onChange={this.onUpdate} name='note' value={note} placeholder="Not Belirtin..." style={{width:'100%', borderColor:'#00000040', borderWidth:'1.5px'}}>

                </textarea>
              </div>:null}
              <div style={{display:'flex', width:'fit-content', marginLeft:'auto', marginBottom:'3px',marginTop:'15px'}}>
                <div style={{paddingRight:'8px', paddingTop:'2px', fontWeight:'500', color:'#0000008f', marginTop:'6px'}}>Adet</div>
                <input onChange={this.onUpdate} type='number' min="1" name='piece' value={piece} style={{width:'50px', borderColor:'#00000040', borderWidth:'1px', height:'fit-content', marginRight:'3px', marginTop:'6px'}}></input>
                <a href="#" class="btn btn-success btn-block" onClick={this.addToCart} style={{ width:'fit-content', marginLeft:'auto'}}>Sepete Ekle</a>
              </div>
              
              
              
            </div>
          </div>
          <div className="col-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" onClick={()=>{this.setState({tabNumber:0})}}>
                <a class={tabNumber==0?"nav-link active":"nav-link"} id="home-tab" style={{cursor:'pointer'}} data-toggle="tab" role="tab" aria-controls="home"
                  aria-selected="true">Ürün Bilgileri</a>
              </li>
              <li class="nav-item" onClick={()=>{this.setState({tabNumber:1})}}>
                <a class={tabNumber==1?"nav-link active":"nav-link"} id="profile-tab" style={{cursor:'pointer'}} data-toggle="tab" role="tab" aria-controls="profile"
                  aria-selected="false">Taksit Seçenekleri</a>
              </li>
            </ul>

            {tabNumber==0?<div style={{border:'1px solid rgba(0,0,0,.125)', borderTopStyle:'none', padding:'10px',marginBottom:'10px',paddingBottom:'30px', width:'100%', overflow:'auto'}} dangerouslySetInnerHTML={{__html:detailedDescription}}></div>:
            <div style={{border:'1px solid rgba(0,0,0,.125)', borderTopStyle:'none', padding:'10px',marginBottom:'10px',paddingBottom:'30px', width:'100%', overflow:'auto', display:'flex', flexWrap:'wrap'}}>
              {['https://merchant.iyzipay.com/static/media/Axess.44ebaa70.svg',
                'https://merchant.iyzipay.com/static/media/World.59e42135.svg',
                'https://merchant.iyzipay.com/static/media/Maximum.8a685c21.svg',
                'https://merchant.iyzipay.com/static/media/Cardfinans.71adfdda.svg',
                'https://merchant.iyzipay.com/static/media/Paraf.8d4eccff.svg',
                'https://merchant.iyzipay.com/static/media/Bonus.1439132b.svg'].map(card=>{
                var ratios=[
                  {taksit:2, oran:101.36}, 
                  {taksit:3, oran:102.31}, 
                  {taksit:6, oran:105.18},
                  {taksit:9, oran:108.45},
                  {taksit:12, oran:111.8}]
                return <div className='col-12 col-md-6 col-lg-4'>
                  <div style={{width:'fit-content',marginRight:'auto',marginLeft:'auto',}}>
                    <img src={card} style={{height:'50px', width:'fit-content'}}></img>
                  </div>
                  
                  <table className="table">
                    <thead>
                        <tr>
                          <th>TAKSİT SAYISI</th>
                          <th>TAKSİT TUTARI</th>
                          <th>TOPLAM TUTAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ratios.map(r=>{
                          return <tr>
                            <td>{r.taksit}</td>
                            <td>{parseFloat((price/100*r.oran)/r.taksit).toFixed(2)}₺</td>
                            <td>{parseFloat((price/100*r.oran)).toFixed(2)}₺</td>
                          </tr>
                        })}
                    </tbody>
                  </table>
                  
                </div>
              })}
            </div>
            }
          </div>
        </div>
        
        

      </div>
      )
  }
}

function mapState(state) {
 
  return state;
}

const connectedHomePage = connect(mapState)(CheckOut);
export default connectedHomePage;