import React,  { Suspense } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css' 
import '../product.css';
import FileBase64 from 'react-file-base64';
import ProductPreview from '../components/productPreview'
//import JoditEditor from "jodit-react";
import axios from 'axios'
import config from '../config'

const JoditEditor = React.lazy(() => import('jodit-react'));

class CheckOut extends React.Component{
  constructor(props){
    super(props);
    this.editor = React.createRef();
    this.state = {
      product:{
        title:'',
        description:'',
        images:[],
        stock:0,
        choices:[//{name:'Renk', options:[]}
        
        ],
        detailedDescription:'',
        price:'',
        noteEnabled:false,
        keywords:[]
      },
      
      choice:{
        name:'',
        options:[]
      }
    }
  }

  componentDidMount(){
    this.getProduct()
  }

  getProduct = async ()=>{
    var {dispatch} = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('Id');
    if(id){
      var {data} = await axios.get(config.apiUri+'/products/getProductE/'+id)
      if(data.err){
        return alert(data.err);
      }
      this.setState({product:data})
    }
    
  }

  saveProduct = async ()=>{
    var {product} = this.state
    console.log( product )
    var {data} = await axios.post(config.apiUri+'/products/saveProduct', {
      product
    })
    if(data.err){
      return alert(data.err);
    }
    alert('Kaydedildi')
    this.setState({product:data})
  }

  onUpdate = (e)=>{
    var u = this.state.product
    if(e.target.type === 'checkbox'){
        u[e.target.name] = e.target.checked
    }else {
        u[e.target.name] = e.target.value
    }
    
    this.setState({product:u})
  }

  onChoiceChange = (e)=>{
    var u = this.state.choice
    if(e.target.type === 'checkbox'){
        u[e.target.name] = e.target.checked
    }else {
        u[e.target.name] = e.target.value
    }
    this.setState({choice:u});
  }

  handleChange = (tags) => {
    var {choice} = this.state
    choice.options = tags
    this.setState({choice})
  }
  addChoice = ()=>{
    var {choice, product} = this.state
    if(choice.name && choice.options.length>0){
      product.choices.push(choice)
      choice = {name:'',options:[]}
      this.setState({choice,product})
    }else {
      alert("Önce Doldurun")
    } 
  }
  getFiles = (file)=>{
    var {product} = this.state
    product.images.push({base64: file.base64})
    this.setState({product})
  }
  removeChoice=(index)=>{
    var {product} = this.state
    product.choices=product.choices.filter((val, i)=>index!=i )
    this.setState({product})
  }

  removeImage = (i)=>{
    var {product} = this.state
    product.images = product.images.filter((img, index)=>index!=i)
    this.setState({product})
  }

  render(){
    var {title,description, choices,price,stock, noteEnabled,images, detailedDescription, isActive, keywords, imgNeeded} = this.state.product
    var {choice} = this.state
    
    return (
      <div  style={{backgroundColor:'rgb(255 255 254)', minHeight:'calc( 100vh - 149px)'}}>

      
        <div class="container" >
          <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel" style={{height:'0vh', paddingTop:'0px'}}>
            <div class="carousel-inner">
              {/*<div class="carousel-item active">
                <img class="d-block w-100" style={{height:'15vh'}} src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRN-E-u70flJEARwkG4hma852N5-0tnA7ofaA&usqp=CAU" alt="First slide"/>
              </div>*/}
            </div>
          </div>
          <div class="row">
              <div class="col-12 col-sm-6">
                  <div class="mb-3 mt-3">
                  <div  style={{padding:'0px'}} className="card col-sm-12">
                    <div className="card-header">
                        Başlık ve Açıklama <i data-tip="Posterde görünmesini istediğiniz mesajları ve yazı tipini belirleyin." className="fas fa-exclamation-circle"></i>
                    </div>
                    <div className="card-body" style={{paddingBottom:'10px'}}>
                        <input onChange={this.onUpdate} type="text" name="title" value={title} className="form-control" placeholder="Ürün Başlığı"/>
                        <input onChange={this.onUpdate} type="text" name="description" value={description} className="form-control" placeholder="Ürün Açıklaması"/>
                        <input onChange={this.onUpdate} type="number" name="price" value={price} className="form-control" placeholder="Fiyatı"/>
                        <input onChange={this.onUpdate} type="number" name="stock" value={stock} className="form-control" placeholder="Stok"/>
                        <label>Not Gereksin Mi:<input style={{marginLeft:'4px'}} name="noteEnabled" type="checkbox" checked={noteEnabled} onChange={this.onUpdate} /> </label>
                        <label style={{marginLeft:'15px'}}>Listelensin Mi?:<input style={{marginLeft:'4px'}} name="isActive" type="checkbox" checked={isActive} onChange={this.onUpdate} /> </label>
                        <label style={{marginLeft:'15px'}}>Fotoğraf İstensin Mi?:<input style={{marginLeft:'4px'}} name="imgNeeded" type="checkbox" checked={imgNeeded} onChange={this.onUpdate} /> </label>
                        
                        <TagsInput value={keywords} onChange={tags=>this.onUpdate({target:{name:'keywords', value:tags}})} inputProps={{placeholder:'Keywords'}} />
                    </div>
                     
                    </div>
                  </div>
                 
              </div>
              
              <div class="col-12 col-sm-6">
                <div class="mb-3 mt-3"> 
                  <div  style={{padding:'0px'}} className="card col-sm-12">
                    <div className="card-header">
                        Fotoğraf Ekle
                    </div>
                    <div className="card-body">
                      <div className="col-12" style={{display:'flex', flexWrap:'wrap'}}>
                        {images.map((img,i)=><div className="col-3" onClick={()=>{this.removeImage(i)}} style={{}}>
                          <img style={{width:'100%'}} src={img.base64}></img>
                        </div>)}
                      </div>
                      <FileBase64
                              multiple={ false }
                              onDone={ this.getFiles } />
                    </div>                    
                  </div>
                  <div  style={{padding:'0px'}} className="card col-sm-12">
                      <div className="card-header">
                          Ürün Seçenekleri <i data-tip="Posterde görünmesini istediğiniz mesajları ve yazı tipini belirleyin." className="fas fa-exclamation-circle"></i>
                      </div>
                      <div className="card-body">
                          <h5 style={{width:'100%', textAlign:'center', marginTop:'0px', marginBottom:'0px'}}>Seçenekler</h5>
                            {choices.map((c,i)=><div style={{display:'flex', width:'100%', position:'relative'}}>
                            <p style={{fontWeight:'700'}}>{c.name} : </p> {c.options.map(o=><p>{o},</p>)}<button onClick={()=>this.removeChoice(i)} style={{position:'absolute', right:'0px'}} type="button" class="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                          </div>)}
                          <div className='card' style={{marginBottom:'0px',marginTop:'0px', padding:'5px'}}>
                            <input onChange={this.onChoiceChange} type="text" name="name" value={choice.name} className="form-control" placeholder="Seçenek Adı"/>
                            <TagsInput value={choice.options} onChange={this.handleChange} inputProps={{placeholder:'Seçenek Ekle'}} />
                            <button onClick={this.addChoice}>Ekle</button>
                          </div>
                      </div>                    
                    </div>
                </div>
              </div>
              
              <div style={{width:'100%'}}>
                <h5 style={{width:'100%', textAlign:'center', marginTop:'30px'}}>Detaylı Açıklama</h5>
                <Suspense fallback={<div>Loading...</div>}>
                  <JoditEditor
                    ref={this.editor}
                    value={detailedDescription}
                    //config={{readonly: false}}
                    tabIndex={1} // tabIndex of textarea
                    //onBlur={newContent => {console.log(newContent);this.onUpdate({target:{name:'detailedDescription', value:newContent}})}} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => {console.log(newContent);this.onUpdate({target:{name:'detailedDescription', value:newContent}})}}
                  />
                </Suspense>
                
              </div>
              <button style={{width:'100%', marginTop:'10px'}} onClick={this.saveProduct} class='btn btn-success'> ÜRÜNÜ KAYDET</button>
              <div class="col">
                  <div class="col" style={{marginTop:'1rem', paddingBottom:'30px'}}>
                    <h5 style={{width:'100%', textAlign:'center', marginTop:'30px'}}>Önizleme</h5>
                    
                    <div class="col-10" style={{marginLeft:"auto", marginRight:'auto'}}>
                      <ProductPreview product={this.state.product}/>
                    </div>
                  </div>
              </div>
              
             
          </div>
      </div>
    </div>)
  }
}

function mapState(state) {
 
  return state;
}

const connectedHomePage = connect(mapState)(CheckOut);
export default connectedHomePage;