import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../config'
import axios from 'axios'

export default class Home extends React.Component{
    constructor(props){
        super(props);

        this.state={

        }
    }

    onChange = (e)=>{
        var state = this.state;
        state[e.target.name] = e.target.value
        this.setState(state)
    }
    
    sendMessage = async ()=>{
        var {data} = await axios.post(config.apiUri+'/PriceCalc/contact', this.state)
        console.log(data)
        if(!data.err){
          this.setState({send:true})
        }else {
            alert(data.err)
        }
    }

    render(){
        var {name, email, subject,message} = this.state;
        return (
            <div class="aboutus-section" style={{ backgroundAttachment:'fixed', perspective:'150%', minHeight:'calc( 100vh - 149px)'}}>
                <div class="container" style={{backgroundColor:'white', marginTop:'60px',marginBottom:'60px', borderRadius:'10px'}}>
                <section>
                    {[
                        "./galleryImages/IMG-3910.jpg",
                        "./galleryImages/IMG-3911.jpg",
                        "./galleryImages/IMG-3912.jpg",
                        "./galleryImages/IMG-3913.jpg",
                        "./galleryImages/IMG-3914.jpg",
                        "./galleryImages/IMG-3915.jpg",
                    ].map(x=><img src={x} />)}
                    
                </section>
                </div>
            </div>
                
        ); 
  }
  
}