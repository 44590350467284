import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'
import config from '../config'
import sizeTexts from '../_constants/sizeTexts'
import { connect } from 'react-redux';
import { cartConstants } from '../_constants';
const siparisStates = {
    O:'Ödeme Alındı',
    H:'Siparişiniz Hazırlanıyor',
    K:'Kargoya Verildi',
    T:'Teslim Edildi',
    M:'Siparişiniz Mailinize Gönderildi',
    I:'Henüz Ödeme Alınmamış',
    IE:'İptal Edildi'
    }
class Home extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            buyer:{},
            items:[]
        }
    }

    componentWillMount = async () => {
        var {dispatch} = this.props;
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('Id');
        const removeBasket = urlParams.get('removeBasket');
        
        var {data} = await axios.get(config.apiUri+'/PriceCalc/getSiparis/'+id)
        console.log(data)
        this.setState(data)
        if(removeBasket){
            dispatch({type:cartConstants.CLEAR})
        }
        
    }
    /*
        telefon: String,
        il: String,
        ilce: String,
        firstName: String,
        lastName: String,
        ip:String,
        email: String,
        address: String
    */
    render(){
        var {buyer, date, _id, items,price,kargoNo,siparisState } = this.state;
        var isTherePhysical = items.some(i=>i.size!=='img')
        isTherePhysical=true
        if(!price){
            return <div style={{minHeight:'50vh'}}>\
                <h5 style={{width:'100%', textAlign:"center", fontSize:'15px', marginTop:'20px'}}>Yükleniyor... </h5>
            </div>
        }
        return (
            <div style={{padding:'20px'}}>
                <div className='col-sm-12 col-md-12 col-lg-12 siparistakip imgShadow' style={{paddingTop:'30px', display:'flex',backgroundColor:'white', flexWrap:'wrap',borderStyle:'solid', 
                    borderColor:'#cccccc', borderWidth:'1px', minHeight:'50vh'}}>
                    <div className='col-sm-12 col-md-6 col-lg-6' style={{marginTop:'15px'}}>
                        <p>{buyer.firstName} {buyer.lastName}</p>
                        <p>{buyer.email}</p>
                        <p>{buyer.address}</p>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-6' style={{marginTop:'15px'}}>
                        <p><u>Tarih : </u> {date}</p>
                        <p><u>Siparis No : </u> {_id}</p>
                        {isTherePhysical? <p><u>Sipariş Durumu : </u> {kargoNo?kargoNo:siparisStates[siparisState]}</p>:null}
                    </div>     
                        
                    <div className='col-sm-12 col-md-12 col-lg-12' style={{marginTop:'15px'}}>
                        <h5>Ürünler </h5>
                        <table class="table">
                            <thead>
                                <tr>
                                <th scope="col">Adı</th>
                                <th scope="col">Başlık</th>
                                <th scope="col">Boyut</th>
                                <th scope="col">Fiyat</th>
                                <th scope="col">Önizle</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item,i)=>{
                                    return (<tr>
                                        <th scope="row">{item.urunType}</th>
                                        <td>{item.firstTitle||item.topTitle}</td>
                                        <td>{sizeTexts[item.size]}</td>
                                        <td>{String(parseFloat(item.price).toFixed(2))} ₺</td>
                                        <td><a href={`/Onizleme?Id=${_id}&index=${i}`}>Tıklayın</a></td>
                                    </tr>)
                                })}  
                                <tr>
                                        <th scope="row"></th>
                                        <td></td>
                                        <td></td>
                                        <td>Toplam</td>
                                        <th>{String(parseFloat(price).toFixed(2))} ₺</th>
                                    </tr>
                            </tbody>
                            
                        </table>


                    </div>   
                    <h5 style={{width:'100%', textAlign:"center", fontSize:'15px', marginTop:'20px'}}>Bizi Tercih Ettiğiniz İçin Teşekkür Ederiz </h5>
                </div>
            </div>
        ); 
    }
  
}

function mapState(state) {
 
    return state;
  }
  
const connectedHomePage = connect(mapState)(Home);
export default connectedHomePage;