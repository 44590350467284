import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
 
export default class Home extends React.Component{
  render(){
    return (
      <div class="container h-100" style={{minHeight:'1vh', padding:'5vh'}}>
        <div class="d-flex justify-content-center h-100">
          <div class="user_card">
            <h5 style={{width:'100%', textAlign:'center', color:'rgba(0, 0, 0, 0.44)'}}>qrmarkets.net</h5>
            <div class="d-flex justify-content-center form_container">
              <form action="/users/register" method="post" enctype='application/x-www-form-urlencoded'>
                <div class="input-group mb-3">
                  <div class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-user"></i></span>
                  </div>
                  <input type="text" name="email" class="form-control input_user" placeholder="Email"/>
                </div>
                <div class="input-group mb-2">
                  <div class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-key"></i></span>
                  </div>
                  <input type="password" name="password" class="form-control input_pass" placeholder="Şifre"/>
                </div>


                <div class="input-group mb-3">
                  <div class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-user"></i></span>
                  </div>
                  <input type="text" name="name" class="form-control input_user" placeholder="Adınız"/>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-user"></i></span>
                  </div>
                  <input type="text" name="surname" class="form-control input_user" placeholder="Soyadınız"/>
                </div>


                  <div class="d-flex justify-content-center mt-3 login_container">
                    <input class="btn btn-primary btn-block" type="submit" value="GİRİŞ"/>
                  </div>
              </form>
            </div>
        
            <div class="mt-4">
              <div class="d-flex justify-content-center links">
                Zaten hesabınız var mı? <a href="/login" class="ml-2">Giriş Yapın</a>
              </div>
              <div class="d-flex justify-content-center links">
                <a href="#">Şifrenizi mi unuttunuz?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      ); 
  }
  
}