import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import ProductPreview from '../components/productPreview'
import axios from 'axios'
import config from '../config'
import history from '../_constants/history'

import '../product.css';


class CheckOut extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      results:[],
      categories:{},
      page:1,
      limit:9,
      selectedFilters:{

      }
    }
  }

  componentDidMount(){
    const urlParams = new URLSearchParams(window.location.search);
    const keywords = urlParams.get('keywords');
    if(keywords){
      console.log(keywords)
      this.setState({selectedFilters:{Kategoriler:[keywords]}}, ()=>{
        this.getProduct()
        this.getCategories()
      })
    }else {
      this.getProduct()
      this.getCategories()
    }
    
  }
  getCategories = async (pg)=>{
    
    var {data} = await axios.get(config.apiUri+`/products/filters`)
    
    if(data.err){
      return alert(data.err);
    }
    
    this.setState({categories:data})
  }

  ToggleCat = (cat, opt)=>{
    var currentCat = this.state.selectedFilters[cat]
    if(!currentCat)currentCat = [];

    if(currentCat.includes(opt)){
      currentCat = currentCat.filter(x=>x!=opt)
    }
    else {
      currentCat.push(opt)
    }
    this.state.selectedFilters[cat] = currentCat
    console.log(currentCat)
    console.log(this.state)
    this.setState({selectedFilters:this.state.selectedFilters},()=>{
      this.getProduct()
    })
  }

  getProduct = async (pg)=>{
    if(!pg){
      pg={
        page:this.state.page,
        limit:this.state.limit
      }
    }

    var {data} = await axios.post(config.apiUri+`/products/get?page=${pg.page}&limit=${pg.limit}`,{
      filters:this.state.selectedFilters
    })
    if(data.err){
      return alert(data.err);
    }
    if(!data.next){data.next=null}
    if(!data.previous){data.previous=null}
    this.setState({...data,  page:pg.page, limit:pg.limit})
  }

  render(){
    var {results, categories, next, previous, page, selectedFilters} = this.state
    
    return (
      <div  style={{backgroundColor:'rgb(255 255 254)', minHeight:'calc( 100vh - 149px)'}}>
        {/*JSON.stringify(this.state)*/}
        <div class="container" >
          <div class="row">
              <div class="col-12 col-sm-3">
                  <div class="mb-3 mt-3">
                      {Object.keys(categories).map(cat=>{
                        var c = categories[cat];
                        if(cat!='Kategoriler')
                          return null;
                        return (
                          <div style={{width:'100%'}}>
                            <div class="card-head" style={{font: '14px', fontWeight:'600', color:'#07193f', borderBottom:'1px solid #e2e2e2', padding:'5px 11px'}}> {cat}</div>
                            <div style={{width:'100%', maxHeight:'20vh', overflow:'auto'}}>
                              <ul onClick={()=>{}} class="list-group bgtransparent" style={{borderStyle:'none', cursor:'pointer'}} >
                                {c.map(x=><li onClick={()=>this.ToggleCat(cat,x)} class="list-group-item bgtransparent" 
                                style={{backgroundColor:selectedFilters[cat]?selectedFilters[cat].includes(x)?'rgb(0 0 0 / 14%)':'white':'white',borderRadius:'5px',marginTop:'2px', borderStyle:'none', paddingBottom:'3px', paddingTop:'3px'}}>{x}</li>)}
                                
                              </ul>
                            </div>
                          </div>)
                      })}
                      {Object.keys(categories).map(cat=>{
                        var c = categories[cat];
                        if(cat=='Kategoriler')
                          return null;
                        return (
                          <div style={{width:'100%'}}>
                            <div class="card-head" style={{font: '14px', fontWeight:'600', color:'#07193f', borderBottom:'1px solid #e2e2e2', padding:'5px 11px'}}> {cat}</div>
                            <div style={{width:'100%', maxHeight:'20vh', overflow:'auto'}}>
                              <ul onClick={()=>{}} class="list-group bgtransparent" style={{borderStyle:'none', cursor:'pointer'}} >
                                {c.map(x=><li onClick={()=>this.ToggleCat(cat,x)} class="list-group-item bgtransparent" 
                                style={{backgroundColor:selectedFilters[cat]?selectedFilters[cat].includes(x)?'rgb(0 0 0 / 14%)':'white':'white',borderRadius:'5px',marginTop:'2px', borderStyle:'none', paddingBottom:'3px', paddingTop:'3px'}}>{x}</li>)}
                                
                              </ul>
                            </div>
                          </div>)
                        })}
                      
                      {/*<div class="card-head" style={{font: '14px', fontWeight:'600', color:'#07193f', borderBottom:'1px solid #e2e2e2', padding:'5px 11px'}}> Tür</div>
                      <div style={{width:'100%', height:'20vh', overflow:'auto'}}>
                        <ul class="list-group bgtransparent" style={{borderStyle:'none'}} >
                          <li class="list-group-item bgtransparent" style={{borderStyle:'none'}}>Bileklik</li>
                          <li class="list-group-item bgtransparent" style={{borderStyle:'none'}}>Küpe</li>
                          <li class="list-group-item bgtransparent" style={{borderStyle:'none'}}>Çanta</li>
                          <li class="list-group-item bgtransparent" style={{borderStyle:'none'}}>Küpe</li>
                          <li class="list-group-item bgtransparent" style={{borderStyle:'none'}}>Çanta</li>
                          <li class="list-group-item bgtransparent" style={{borderStyle:'none'}}>Küpe</li>
                          <li class="list-group-item bgtransparent" style={{borderStyle:'none'}}>Çanta</li>
                        </ul>
                      </div>
                      
                      
                      <div class="card-head" style={{font: '14px', fontWeight:'600', color:'#07193f', borderBottom:'1px solid #e2e2e2', padding:'5px 11px'}}> Özellik</div>
                      <div style={{width:'100%', height:'20vh', overflow:'auto'}}>
                        <ul class="list-group bgtransparent" style={{borderStyle:'none'}} >
                          <li class="list-group-item bgtransparent" style={{borderStyle:'none'}}>Bileklik</li>
                          <li class="list-group-item bgtransparent" style={{borderStyle:'none'}}>Küpe</li>
                          <li class="list-group-item bgtransparent" style={{borderStyle:'none'}}>Çanta</li>
                          <li class="list-group-item bgtransparent" style={{borderStyle:'none'}}>Küpe</li>
                          <li class="list-group-item bgtransparent" style={{borderStyle:'none'}}>Çanta</li>
                          <li class="list-group-item bgtransparent" style={{borderStyle:'none'}}>Küpe</li>
                          <li class="list-group-item bgtransparent" style={{borderStyle:'none'}}>Çanta</li>
                        </ul>
                    </div>*/}
                  </div>
              </div>
              <div class="col">
                  <div class="row" style={{marginTop:'1rem'}}>
                      
                      {results.map(r=>{
                        return (<div class="col-12 col-sm-6 col-md-6 col-lg-4" onClick={()=>{history.push('/Product?Id='+r._id)}} style={{padding:'0px'}} >
                        <a href={'/Product?Id='+r._id}><ProductPreview product={r}/></a>
                      </div>)})}

                      
                  </div>
              </div>

          </div>
          <div style={{display:'flex',width:'100%', flexWrap:"wrap"}}>
            <div class="col-12 col-sm-3 col-md-3 col-lg-3"></div>
            <div class="col-12 col-sm-9 col-md-9 col-lg-9 mt-3" style={{display:'flex'}}>
                <div style={{marginLeft:'auto', marginRight:'auto'}}>
                  <nav aria-label="..." >
                      <ul class="pagination">
                         
                          <li class={previous?"page-item":"page-item disabled"} onClick={()=>{return previous?this.getProduct(next):null}}>
                              <a class="page-link" tabindex="-1">Geri</a>
                          </li>
                          {previous?<li onClick={()=>{this.getProduct(previous)}} class="page-item"><a class="page-link" href="#">{previous.page}</a></li>:null}
                          <li class="page-item active">
                              <a class="page-link" >{page} <span class="sr-only">(current)</span></a>
                          </li>
                          {next?<li onClick={()=>{this.getProduct(next)}} class="page-item"><a class="page-link" >{next.page}</a></li>:null}
                          
                          <li class={next?"page-item":"page-item disabled"} onClick={()=>{return next?this.getProduct(next):null}}>
                              <a class="page-link" >İleri</a>
                          </li>
                      </ul>
                  </nav>
                </div>
                
                
            </div>
          </div>
          
      </div>
    </div>)
  }
}

function mapState(state) {
 
  return state;
}

const connectedHomePage = connect(mapState)(CheckOut);
export default connectedHomePage;