const widths = {
    S:15,
    M:21,
    L:40,
    XL:50,
    'xsm-c':{w:13,h:18},
    'img':{w:0,h:0},
    'sm-c':{w:21,h:30},
    'm-c':{w:30,h:40},
    'lg-c':{w:50,h:70},  
  }

export default widths;