import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import 'react-tagsinput/react-tagsinput.css' 
import '../product.css';
import ProductPreview from '../components/productPreview'
import axios from 'axios'
import config from '../config'

class CheckOut extends React.Component{
  constructor(props){
    super(props);
    this.editor = React.createRef();
    this.state = {
      results:[],
    }
  }

  componentDidMount(){
    this.getProduct()
  }

 
  getProduct = async (pg)=>{
    if(!pg){
      pg={
        page:1,
        limit:9
      }
    }
    var {data} = await axios.post(config.apiUri+`/products/get?page=${pg.page}&limit=${pg.limit}`)
    if(data.err){
      return alert(data.err);
    }
    this.setState(data)
  }

  render(){
    var { results } = this.state
    
    return (
      <div  style={{backgroundColor:'rgb(255 255 254)', minHeight:'calc( 100vh - 149px)'}}>

      
        <div class="container" >
          <ul class="list-group">
            {results.map(r=>{

              return (<li class="list-group-item col-12" style={{flexWrap:'wrap', display:'flex', overflow:'auto'}}>
                <div class='col-2'>
                  <ProductPreview product={r} options={{hideDesc:true}}/>
                </div>
                <div class='col-10'>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Başlık</th>
                        <th scope="col">Açıklama</th>
                        <th scope="col">Fiyat</th>
                        <th scope="col">Aktif</th>
                        <th scope="col">Düzenle</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">{r.title}</th>
                        <td>{r.description}</td>
                        <td>{r.price}</td>
                        <td >
                          <div style={{backgroundColor:r.isActive?'green':'red', width:'20px', height:'20px', borderRadius:'10px'}}></div>
                        </td>
                        <td><a href={`/editProduct?Id=${r._id}`}>Düzenle</a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>)
            })}
            {JSON.stringify(this.state)}
            
          </ul>
        </div>
    </div>)
  }
}

function mapState(state) {
 
  return state;
}

const connectedHomePage = connect(mapState)(CheckOut);
export default connectedHomePage;