import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
 
export default class Footer extends React.Component{
  render(){
    return (
        <footer class="page-footer font-small white pt-4" style={{backgroundColor:'#e6e6e6'}}>

			<div class="container-fluid text-center text-md-left">
		  
			  <div class="row">
		  
				<div class="col-md-6 mt-md-0 mt-3">
		  
				  
				  <h5 class="text-uppercase">Türkiyenin ilk QR Hediye sayfasına HOŞ GELDİNİZ. </h5>
				  
				  <p>Qr kod iPhone’larda kamera üzerinden, Androidlerde ise google lens üzerinden dinlenebilmektedir. Google lens uygulamasını indirmenize gerek yoktur. Okutamadığınız durumlarda Qrscanner uygulamasını indirerek okutabilirsiniz.</p>
		 		
				</div>
		  
				<hr class="clearfix w-100 d-md-none pb-3"/>
		  
				<div class="col-md-3 mb-md-0 mb-3">
		  
				  <h5 class="text-uppercase">Bağlantılar</h5>
		  
				  <ul class="list-unstyled">
					{<li>
							<a href="https://www.shopier.com/qrmarkets"><img src="https://www.shopier.com/favicon-32x32.png" style={{height:'20px'}}/> </a>
					 
					</li>}
					<li>
							<a href="#!"><img src="/images/visamaster.png" style={{height:'20px'}}/> </a>
					 
					</li>
					<li>
						<a href="https://www.instagram.com/qrmarkets/" style={{fontWeight:'600'}}><i class="fab fa-instagram"></i> qrmarkets</a>
					</li>
					
				  </ul>
		  
				</div>
				<div class="col-md-3 mb-md-0 mb-3" style={{display:'none'}}>
		  
				  
				 
				</div>
		  
			  </div>
		  
			</div>
			<div class="footer-copyright text-center py-3">© 2020 Copyright:
			  <a href="https://www.qrmarkets.net/"> qrmarkets.net</a>
			</div>
		  
		  </footer>
      );
  }
  
}