import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css'
import moment from 'moment'
import Poster from './poster'
import stages from './stages'
import StepProgressBar from 'react-step-progress';
// import the stylesheet
import axios from 'axios'
import config from '../../config'
import 'react-step-progress/dist/index.css';

class Starmap extends React.Component{
  constructor(props){
    super(props);
    var date = new Date();
    date.setHours(12)
    date.setMinutes(0) 
    this.state = {
      type:'Yıldız Haritası',
      topTitle:'',
      firstTitle:'',
      showDate:false, 
      currentStage:0,
      date:date,
      eksen:true,
      font:'lobster',
      location: {lat: 41.0082376, lng: 28.9783589},
      locationText: "İstanbul, Türkiye",
      size:'34x44',
      mask:'canvas'
    }
    console.log(moment(new Date()).format('YYYY-MM-DDThh:mm'))
  }
  componentWillMount = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('Id');
    const index = urlParams.get('index');
    var {data} = await axios.get(config.apiUri+'/PriceCalc/getSiparis/'+id)
    var _id = data._id;
    data = data.items[index]
    var image = new Image();
    window.bgPictures={}
    image.onload = function() {
        window.bgPictures["celestial-map1"]=image
    };
    image.src = data.base64image
    window.crop=data.crop;
    this.setState({...data, _id})
  }

  stageDataUpdated = (newState)=>{
    //console.log(newState)
    this.setState(newState);
    setTimeout(() => {
      //document.getElementById('lat').__onchange();
    }, 300);
  }

  render(){
    
    var {currentStage, mask} = this.state
    var stageList = [
      <stages.Stage3 key={3} directDownload={true} onUpdate={this.stageDataUpdated} state={this.state}/>,
      <stages.Stage4 key={3} directDownload={true} onUpdate={this.stageDataUpdated} state={this.state}/>,
      <stages.Stage1 key={1} directDownload={true} onUpdate={this.stageDataUpdated} state={this.state}/>,
      //<stages.Stage2 key={2} onUpdate={this.stageDataUpdated} state={this.state}/>,
      
      
      
    ]
    return (
            <div  style={{ minHeight:'calc( 100vh - 149px)'}}>
              <div className='container' style={{width:'100%'}}>
                <div className="flexbox col-sm-12" style={{marginLeft:'auto',paddingTop:'30px',paddingBottom:'30px', width:'100%', paddingLeft:'0px', paddingRight:'0px'}} >
                  <div className="flexbox col-sm-12" >
                    <Poster key={'map'+mask} state={this.state}/>
                    <div className="col-sm-12 col-md-6 col-lg-7">
                      {stageList[currentStage]}
                    </div>
                    
                  </div>
                  
              </div>
              </div>
            </div>
            
           
        
      ); 
  }
  
}

export { Starmap as CreateAndDownload}
