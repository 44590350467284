import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css'
import moment from 'moment'
import Poster from './poster'
import stages from './stages'
import StepProgressBar from 'react-step-progress';
// import the stylesheet
import $ from 'jquery'
import 'react-step-progress/dist/index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const notify = (e) => toast.error(e, {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });
  
class Starmap extends React.Component{
  constructor(props){
    super(props);
    var date = new Date();
    date.setHours(12)
    date.setMinutes(0)
    const whichpage = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    }).type
    this.state = {
      type:'Yıldız Haritası',
      topTitle:'',
      firstTitle:'',
      showDate:false, 
      plastikstand: false,
      currentStage:0,
      date:date,
      hediyePaketi:true,
      eksen:true,
      font:'lobster',
      location: {lat: 41.0082376, lng: 28.9783589},
      locationText: "İstanbul, Türkiye",
      size:'34x44',
      mask: whichpage === 'music' ? 'transparent_gift' : 'canvas'
    }
    console.log(moment(new Date()).format('YYYY-MM-DDThh:mm'))
  }
  componentDidMount = ()=>{
    /*alert(`   Önemli Duyuru

14 Şubat Yoğunluğu Sebebiyle Bugünden İtibaren Oluşturacağınız Siparişler 15 Şubat günü Kargoya Verilebilecektir.

Gösteridiğiniz ilgi ve alaka için teşekkür eder. Saygılarımızı sunarız.`)*/
    setTimeout(() => {
      
    }, 5000);
  }
  stageDataUpdated = (newState)=>{
    //console.log(newState)
    //secretmsg base64Sound
    if(newState.secretmsg) {
      if(this.state.base64Sound){
        notify('Bağlantı adresi eklediğiniz için ses kaydı kaldırılmıştır. Yalnızca birini seçiniz!') 
      }
        
      newState.base64Sound = null;
    }
    if(newState.base64Sound) { 
      if(this.state.secretmsg){
         notify('Ses kaydı eklediğiniz için bağlantı adresi kaldırılmıştır. Yalnızca birini seçiniz!')
      }
       
      newState.secretmsg = '';
    }
    this.setState(newState);
    
    setTimeout(() => {
      //document.getElementById('lat').__onchange();
    }, 300);
  }

  render(){
    
    var {currentStage, mask} = this.state
    var stageList = [
      <stages.Stage3 key={3} onUpdate={this.stageDataUpdated} state={this.state}/>,
      <stages.Stage4 key={3} onUpdate={this.stageDataUpdated} state={this.state}/>,
      <stages.Stage1 key={1} onUpdate={this.stageDataUpdated} state={this.state}/>,
      <stages.Stage4p5 key={45} onUpdate={this.stageDataUpdated} state={this.state}/>,
      <stages.Stage5 key={1} onUpdate={this.stageDataUpdated} state={this.state}/>,
      //<stages.Stage2 key={2} onUpdate={this.stageDataUpdated} state={this.state}/>,
      
      
      
    ]
    return (
            <div  style={{ minHeight:'calc( 100vh - 149px)'}}>
              <div className='container' style={{width:'100%'}}>
                <div className="flexbox col-sm-12" style={{marginLeft:'auto',paddingTop:'30px',paddingBottom:'30px', width:'100%', paddingLeft:'0px', paddingRight:'0px'}} >
                <ToastContainer />
                  <div className="flexbox col-sm-12" >
                    <Poster key={'map'+mask} state={this.state} />
                    <div className="col-sm-12 col-md-6 col-lg-7">
                      {stageList[currentStage]}
                    </div>
                    
                  </div>
                  
              </div>
              </div>
            </div>
            
           
        
      ); 
  }
  
}

export { Starmap as Starmap}
