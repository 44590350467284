import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'
import { connect } from 'react-redux';
import { cartConstants } from '../_constants';
import NumberFormat from 'react-number-format';
import config from '../config'
import SelectSearch from 'react-select-search';
import ililce from './ililce'
import Sozlesme from './Sozlesme'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const notify = (e) => toast.error(e, {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });

var starmapParamsList = [
  {"key":"topTitle","text":"Üst Başlık"},
  {"key":"secondTopTitle","text":"2. Üst Başlık"},

  {"key":"subTitle","text":"Alt Başlık"},
  {"key":"subTitle2","text":"2. Alt Başlık"},

  {"key":"locationText","text":"Konum"},
 
  {"key":"size","text":"Boyut"},
]

const formatPrice = number=>String(parseFloat(number).toFixed(2)).replace('.',',')

const Detaylar = (props)=>{
  const [open, setOpen] = useState(false);
  var { details } = props
  console.log(details)
  return <div>
      <div className="col-sm-12 col-md-12" onClick={()=>{setOpen(!open)}} style={{fontSize:"14px", padding:'0px !important', cursor:'pointer'}}>
        <i class={"fas fa-chevron-"+(open?'up':'down')}></i> Detaylar
      </div>
      {open?<table class="table">
        <tr><th>Parametre</th><th>Deger</th></tr>
        {starmapParamsList.map(x=>
          <tr><th>{x.text}</th><td>{details[x.key]}</td></tr>
          )}
      </table>:null}
  </div>
}
class CheckOut extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        items:[],
        price:'',
        telefon:'',
        promocode:'',
        il:'Adana',
        ilce:'Aladağ',
        fatura_istemiyorum:true
    }
  }

  componentDidMount(){
    this.getTotalPrice()
  }
  componentWillReceiveProps(){
    setTimeout(() => {
      this.getTotalPrice()
    }, 1000);
    
  }
  getTotalPrice = async ()=>{
    var {Cart} = this.props;
    var {items} = Cart
    
    var {data} = await axios.post(config.apiUri+'/PriceCalc/calcPrice', {
      items:items,
      promocode:this.state.promocode
    })
    console.log(data)
    if(!data.err){
      this.setState({items:data.items, price:data.price,kargo:data.kargo, priceFetched:true, promovalid:data.items.some(i=>i.originalPrice&&i.originalPrice!=i.price)})
    }
   
  }

  checkout = async ()=>{
    var {Cart} = this.props;
    var {items} = Cart
    var buyer = this.state;
    var {gizlilik_sozlesmesi, satis_sozlesmesi}=this.state;
    if(!satis_sozlesmesi){
      return notify('Lütfen Gizlilik ve Satış sözleşmesini onaylıyorum seçeneğini işaretleyiniz.')
    }
    
    
    var {data} = await axios.post(config.apiUri+'/PriceCalc/CheckOut', {
      items:items,
      buyer,
      promocode:this.state.promocode
    })
    if(!data.err){
      try{
        //alert(config.apiUri+'/PriceCalc/getPayment/'+data.id)
        //alert(data)
        //history.push(config.apiUri+'/PriceCalc/getPayment/'+data.id)
        window.location.replace(config.apiUri+'/PriceCalc/getPayment/'+data.id);
        //window.eval(data.checkoutFormContent)
      }catch(e){
        notify(e)
      }
    }else {
      notify(data.err)
    }
   
  }

  onChange=(e)=>{
    var s = this.state;
    s[e.target.id]=e.target.value
    this.setState(s);
    console.log(s)
  }

  onPromoCodeChange=(e)=>{
    if(e.target.value.length<=8){
      var s = this.state;
      s[e.target.id]=e.target.value
      this.setState(s);
    }
    this.getTotalPrice()
  }

  render(){
    var {Cart, dispatch} = this.props;
    var len = Cart.items.length
    var {items,priceFetched,fatura_istemiyorum, price,kargo, promovalid, telefon, promocode, firstName,lastName, email, il, ilce, address, fatura,satis_sozlesmesi,gizlilik_sozlesmesi, ShowSozlesme,ShowGizlilik} = this.state
    
    var promostyle=promovalid||promocode.length==0?{}:{borderColor:'#ff000063', borderWidth:'2px', borderStyle:'solid'}
    if(promovalid){
      promostyle={borderColor:'#0080004a', borderWidth:'2px', borderStyle:'solid'}
    }
    if(!priceFetched){
      return (<div class="container" style={{height:'80vh', paddingTop:'30vh'}}>
        
      </div>)
    }

    if(items.length<1&&priceFetched){
      return (<div class="container" style={{height:'80vh', paddingTop:'30vh'}}>
        <div style={{width:'100%', alignItems:'center', textAlign:'center'}}>
              <h4>SEPETİNİZ BOŞ</h4>
              <a href='/'  style={{marginLeft:'auto', marginRight:'auto'}}>
                <button class="btn btn-primary btn-lg col-sm-6">Bir Yıldız Haritası Oluşturun</button>
              </a>
        </div>
      </div>)
    }

    return (
      <div style={{padding:'5px',width:'100%', minHeight:'calc( 100vh - 149px)', paddingTop:'45px', paddingBottom:'45px'}}>

      <ToastContainer />
      <div class="container checkoutContainer" >
          {ShowSozlesme?<Sozlesme onClose={()=>{this.setState({ShowSozlesme:false})}}/>:null}
          {/*ShowGizlilik?<Gizlilik onClose={()=>{this.setState({ShowGizlilik:false})}}/>:null*/}
         

          <div class="row">
            <div class="col-md-4 order-md-1 mb-4">
              <h4 class="d-flex justify-content-between align-items-center mb-3">
                <span class="text-muted">Sepetiniz</span>
                <span class="badge badge-secondary badge-pill">{len}</span>
              </h4>
              <ul class="list-group mb-3">
                {items.map((item,i)=>{
                  if(item.type=="Generic" || item.urunType=="Generic"){
                    var {product} = item;
                    if(!product)product={};
                    return (<li class="list-group-item d-flex justify-content-between lh-condensed">
                      <div>
                        <h6 class="my-0">{product.title} ({item.piece})</h6>
                        <small class="text-muted">{product.description?product.description.substr(0,15):''}...</small>
                      </div>
                      <h5 class="text-muted" style={{marginLeft:'auto', fontSize:'17px', marginRight:'6px', fontWeight:'500',color:item.price!=item.originalPrice?'green':''}}>
                          <s  style={{marginRight:'15px', color:'red'}}>{item.price!=item.originalPrice?formatPrice(item.originalPrice)+'₺':null}</s> {formatPrice(item.price)}₺
                      </h5>
                       
                      
                      <button onClick={()=>{dispatch({type:cartConstants.REMOVE, index:i})}} type="button" class="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                       
                    </li>)
                  }
                  if(item.type=="Yıldız Haritası" || item.urunType=="Yıldız Haritası"){
                    return (<li class="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                      <h6 class="my-0">{item.type}</h6>
                      <small class="text-muted">{item.topTitle}</small>
                    </div>
                    <h5 class="text-muted" style={{marginLeft:'auto', fontSize:'17px', marginRight:'6px', fontWeight:'500',color:item.price!=item.originalPrice?'green':''}}>
                        <s style={{marginRight:'15px', color:'red'}}>{item.price!=item.originalPrice?formatPrice(item.originalPrice)+'₺':null}</s> {formatPrice(item.price)}₺
                    </h5>
                    
                    
                    <button onClick={()=>{dispatch({type:cartConstants.REMOVE, index:i})}} type="button" class="close" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    
                  </li>)
                  }
                })}
                {kargo.price?<li class="list-group-item d-flex justify-content-between lh-condensed">
                      <div>
                        <h6 class="my-0">Kargo : </h6>
                        <small class="text-muted"></small>
                      </div>
                      <h5 class="text-muted" style={{marginLeft:'auto', fontSize:'17px', marginRight:'6px', fontWeight:'500',color:''}}>
                          {formatPrice(kargo.price)}₺
                      </h5>
                       
                    </li>:null}
                <li class="list-group-item d-flex justify-content-between">
                  <span>Toplam (₺)</span>
                  <strong>{formatPrice(price)}₺</strong>
                </li>
              </ul>
              {/*<div class="mt-3" style={{position:'relative'}}>
                  <h5 style={{marginLeft:'20px', fontSize:'15px'}}>Fatura İstemiyorum (Hediye Olacak)</h5>
                  <input style={{position:'absolute', top:'3px', left:'0px'}} type="checkbox" value={fatura} onChange={e=>{this.setState({fatura:e.target.checked})}}/>
              </div>*/}
              {/*<div class="col-md-12 mb-3" style={{padding:'0px'}}>
                    <input type="text" class="form-control" name='promocode' size={`8`} onChange={this.onPromoCodeChange} id="promocode" placeholder="PROMOSYON KODU" style={{fontSize:'13px', ...promostyle}} value={promocode}/>
            </div>*/}
              <div class="mt-3 d-none d-sm-none d-md-block" style={{position:'relative'}}>
                  <h5 style={{marginLeft:'20px', fontSize:'15px'}}><u style={{cursor:'pointer'}} onClick={e=>this.setState({ShowSozlesme:true})}>Mesafeli Satış Sözleşmesi</u>'ni Okudum Onaylıyorum</h5>
                  <input style={{position:'absolute', top:'3px', left:'0px'}} type="checkbox" checked={satis_sozlesmesi} onChange={e=>{this.setState({satis_sozlesmesi:e.target.checked})}}/>
              </div>
              <div class="mt-3 d-none d-sm-none d-md-block" style={{position:'relative'}}>
                  <h5 style={{marginLeft:'20px', fontSize:'15px'}}>Fatura İstemiyorum (Hediye Olacak)</h5>
                  <input style={{position:'absolute', top:'3px', left:'0px'}} type="checkbox" checked={fatura_istemiyorum} onChange={e=>{this.setState({fatura_istemiyorum:e.target.checked})}}/>
              </div>
              {/*<div class="mt-3 d-none d-sm-none d-md-block" style={{position:'relative'}}>
                  <h5 style={{marginLeft:'20px', fontSize:'15px'}}><u style={{cursor:'pointer'}} onClick={e=>this.setState({ShowGizlilik:true})}>Gizlilik Sözleşmesi</u>'ni Okudum Onaylıyorum</h5>
                  <input style={{position:'absolute', top:'3px', left:'0px'}} type="checkbox" value={gizlilik_sozlesmesi} onChange={e=>{this.setState({gizlilik_sozlesmesi:e.target.checked})}}/>
            </div>*/}
              <button class="btn btn-primary btn-lg btn-block d-none d-sm-none d-md-block"  onClick={this.checkout}>Ödeme</button>
              {/*<form class="card p-2">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Promo code"/>
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-secondary">Redeem</button>
                  </div>
                </div>
              </form>*/}
            </div>
            <div class="col-md-8 order-md-1">
              <h4 class="mb-3">Bilgiler</h4>
              
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="firstName">İsim</label>
                    <input type="text" class="form-control" onChange={this.onChange} id="firstName" placeholder="İsim" value={firstName} required/>
                    <div class="invalid-feedback">
                      Valid first name is required.
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="lastName">Soyisim</label>
                    <input type="text" class="form-control"  onChange={this.onChange} id="lastName" placeholder="Soyisim" value={lastName} required/>
                    <div class="invalid-feedback">
                      Valid last name is required.
                    </div>
                  </div>
                </div>

                {/*<div class="mb-3">
                  <label for="username">Username</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">@</span>
                    </div>
                    <input type="text" class="form-control" id="username" placeholder="Username" required/>
                    <div class="invalid-feedback" style={{width: '100%'}}>
                      Your username is required.
                    </div>
                  </div>
                </div>*/}

                <div class="mb-3">
                  <label for="email">Email </label>
                  <input type="email" required class="form-control" onChange={this.onChange} id="email" value={email} placeholder="Email"/>
                  <div class="invalid-feedback">
                    Please enter a valid email address for shipping updates.
                  </div>
                </div>
                
                <div class="mb-3">
                  <label for="email">Telefon </label>
                  <NumberFormat value={telefon} onChange={(e)=>{console.log(e.target.value);this.setState({telefon:e.target.value})}} class="form-control" placeholder="(555)-555-55-55" format="(###)-###-##-##" mask="_"/>
                  {/*<input type="email" required class="form-control" id="telefon" placeholder="(535)-535-35-35"/>
                  <div class="invalid-feedback">
                    Please enter a valid email address for shipping updates.
                  </div>*/}
                </div>
                <div class="col-md-12" style={{padding:'0px'}}>
                  <label for="address">İl/İlçe</label>
                  <div class="col-md-12" style={{display:'flex', flexWrap:'wrap',padding:'0px'}}>
                    <div class="col-sm-6 col-md-6 col-lg-6" style={{paddingLeft:'0px'}}>
                      <SelectSearch search={true} options={Object.keys(ililce).map(il=>({name: il, value: il}))} onChange={(e)=>{this.setState({il:e})}} value={il} name="il1" placeholder="İl Seçiniz" />
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6" style={{padding:'0px'}}>
                      {ililce[il]?<SelectSearch search={true} options={ililce[il].ilceleri.map(ilce=>({name: ilce, value: ilce}))} onChange={(e)=>{this.setState({ilce:e})}} value={ilce} name="ilce1" placeholder="İlçe Seçiniz" />:null}
                    </div>
                  </div>
                  <div class="invalid-feedback">
                    Please enter your shipping address.
                  </div>
                </div>
                <div class="mb-3">
                  <label for="address">Adres</label>
                  <textarea type="text" class="form-control" onChange={this.onChange} id="address" value={address} placeholder="Adres Detayları" required/>
                  <div class="invalid-feedback">
                    Please enter your shipping address.
                  </div>
                </div>
                <div class="d-sm-block d-md-none" style={{position:'relative'}}>
                  <h5 style={{marginLeft:'20px', fontSize:'15px'}}><u style={{cursor:'pointer'}} onClick={e=>this.setState({ShowSozlesme:true})}>Mesafeli Satış Sözleşmesi</u>'ni Okudum Onaylıyorum</h5>
                  <input style={{position:'absolute', top:'3px', left:'0px'}} type="checkbox" value={satis_sozlesmesi} onChange={e=>{this.setState({satis_sozlesmesi:e.target.checked})}}/>
                </div>
                {/*<div class="d-sm-block d-md-none" style={{position:'relative'}}>
                    <h5 style={{marginLeft:'20px', fontSize:'15px'}}><u style={{cursor:'pointer'}} onClick={e=>this.setState({ShowGizlilik:true})}>Gizlilik Sözleşmesi</u>'ni Okudum Onaylıyorum</h5>
                    <input style={{position:'absolute', top:'3px', left:'0px'}} type="checkbox" value={gizlilik_sozlesmesi} onChange={e=>{this.setState({gizlilik_sozlesmesi:e.target.checked})}}/>
                </div>*/}
                <button class="btn btn-primary btn-lg btn-block d-sm-block d-md-none" style={{marginBottom:'30px'}} onClick={this.checkout}>Ödeme</button>
                {/*<hr class="mb-4"/>*/}
                
                
             
            </div>
          
          </div>

        
        </div>
        </div>
      ); 
  }
}

function mapState(state) {
 
  return state;
}

const connectedHomePage = connect(mapState)(CheckOut);
export default connectedHomePage;