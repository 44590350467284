import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../config'
import axios from 'axios'

const siparisStates = {
    O:'Ödeme Alındı',
    H:'Siparişiniz Hazırlanıyor',
    K:'Kargoya Verildi',
    T:'Teslim Edildi',
    M:'Siparişiniz Mailinize Gönderildi',
    I:'Henüz Ödeme Alınmamış',
    IE:'İptal Edildi'
    }

export default class Home extends React.Component{
    constructor(props){
        super(props);

        this.state={
            data:[],
            loading:true,
            brightness:1.2,
            textScale:1
        }
        this.checkAdmin()
    }

    checkAdmin = async ()=>{
        var {data} = await axios.get(config.apiUri+'/PriceCalc/checkLogin')
        if(data.err){
            return window.location.href = config.apiUri+'/login'
        }
       
        this.getRecords()
    }

    getRecords = async ()=>{
        this.setState({data:[], loading:true})
        var {search} = this.state;
        var url = search?'/PriceCalc/searchRecord/'+search:'/PriceCalc/getRecords'
        var {data} = await axios.get(config.apiUri+url)
        this.setState({data, loading:false})
    }

    updateState = async (id, status)=>{
        console.log(id, status)
        var {data} = await axios.get(config.apiUri+`/PriceCalc/updateStatus/${id}/${status}`)
        if(!data.err){
            console.log('Statüs Güncellendi')
        }else {
            alert('Statüs Güncellenemedi Lütfen Kontrol Edin')
        }
        this.checkAdmin()
    }

    updateRedirect = async (id, status)=>{
        console.log(id, status)
        var {data} = await axios.post(config.apiUri+`/PriceCalc/updateRedirect`,{
            id:id,
            status:status
        })
        if(!data.err){
            alert('Statüs Güncellendi')
        }else {
            alert('Statüs Güncellenemedi Lütfen Kontrol Edin')
        }
        this.checkAdmin()
    }
    updateUserName = async (id, status)=>{
        console.log(id, status)
        var {data} = await axios.post(config.apiUri+`/PriceCalc/updateBuyer`,{
            id:id,
            status:status
        })
        if(!data.err){
            alert('isim Güncellendi')
        }else {
            alert('isim Güncellenemedi Lütfen Kontrol Edin')
        }
        this.checkAdmin()
    }

    render(){
        const boyutlar = {
            "sm-c":`Büyük Çerçeveli`,
            "m-c":`Orta Çerçeveli`,
            "img":`Sanal`,
            "10x15":"10x15",//18% toptitle = 3.8vw
            "13x18":"13x18",//18%
            
            "25x34":"25x34",//21x30 //13% //dış çerçeve orantısını 3% yap
            "34x44":"34x44",//30x40 //11% //beyaz arka plan, kare tik i ekle
            
        }
        var {name, email, subject,message, data, search, loading} = this.state;
        return (
            <div class="aboutus-section" style={{backgroundImage:'url(https://www.gokyuzun.com/images/space.jpg)', backgroundAttachment:'fixed',padding:'5px', backgroundColor:'#f8f9fa',backgroundColor:'#f7fafc',perspective:'150%', minHeight:'calc( 100vh - 149px)'}}>
                
                <div class="" style={{backgroundColor:'white', width:'100%', marginTop:'60px',marginBottom:'60px', borderRadius:'10px'}}>
                <div class="input-group" style={{paddingTop:"5px", paddingLeft:'5px'}}>
                    <div class="form-outline">
                        <input type="search" value={search} onKeyDown={(e)=>{if(e.key=='Enter')this.checkAdmin()}} onChange={(e)=>{this.setState({search:e.target.value})}} id="form1" placeholder='Müşteri veya Başlık' class="form-control" style={{margin:'0px'}}/>
                    </div>
                </div>
               
                {<a href="/CreateAndDownload" target="_blank" style={{float:'right'}}>Yarat ve indir</a>}
                {loading?<h5 style={{padding:'10px'}}>Yükleniyor... Lütfen Bekleyin</h5>:null}
                <label>Parlaklık</label><input type='number' onChange={(e)=>{this.setState({brightness:e.target.value})}} value={this.state.brightness}></input>
                <label>Yazı Boyutu Oranı</label><input type='number' onChange={(e)=>{this.setState({textScale:e.target.value})}} value={this.state.textScale}></input>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'150px'}}>Sipariş Durumu</th>
                            <th scope="col" style={{width:'150px'}}>Yönlendirme</th>
                            
                            <th scope="col" style={{width:'150px'}}>Ses Dosyasını İndir</th>
                            <th scope="col" style={{width:'150px'}}>Foto Dosyasını İndir</th>
                            <th scope="col" style={{width:'150px'}}>Ad Soyad</th>
                            <th scope="col" style={{width:'150px'}}>Başlıklar</th>
                            <th scope="col" style={{width:'150px'}}>Alt Başlık</th>
                            <th scope="col" style={{width:'150px'}}>Qr İndir</th>
                            <th scope="col">Düzenle ve İndir</th>
                        </tr>
                    </thead>
                    <tbody id="siparisler">
                        {data.map((x, index)=>{
                            var {_id, siparisState, kargoTakip, items } = x;
                            //var {firstName,lastName, il, ilce, address, telefon} = x.buyer;
                            return <tr key={_id}>
                                <td>
                                    <select  className="form-control" onChange={(e)=>{ this.updateState(_id, e.target.value) }} value={siparisState}>
                                        {Object.keys(siparisStates).map(k=>{
                                            return <option value={k}>{siparisStates[k]}</option>
                                        })}
                                    </select>
                                </td>
                                <td style={{display:'flex'}}><input value={items[0].secretmsg} onChange={(e)=>{
                                    data[index].items[0].secretmsg = e.target.value;
                                    this.setState(this.state)
                                }}></input><button className="btn btn-success btn-sm" onClick={()=>{
                                    this.updateRedirect(_id, items[0].secretmsg)
                                }}>Kaydet</button></td>

                               
                                <td>
                                    {<button onClick={()=>{
                                        axios({
                                            url: config.apiUri+`/PriceCalc/downloadSound/${_id}`, //your url
                                            method: 'GET',
                                            responseType: 'blob', // important
                                          }).then((response) => {
                                                if(response.data.err){
                                                    return alert('Ses Yok')
                                                }
                                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                                const link = document.createElement('a');
                                                link.href = url;
                                                link.setAttribute('download', items[0].buyerName+'.wav'); //or any other extension
                                                document.body.appendChild(link);
                                                link.click();
                                          });
                                    }}>
                                        Ses indir
                                    </button>}
                                   {/* <audio controls="controls" autobuffer="autobuffer" style={{width:'100%'}}>
                                        <source src={config.apiUri+`/PriceCalc/downloadSound/${_id}`} />
                                    </audio>*/}
                                </td>
                                <td>
                                    {<button onClick={()=>{
                                        window.open(config.apiUri+`/PriceCalc/downloadMusteriImage/${_id}`, 'blank')
                                        /*axios({
                                            url: config.apiUri+`/PriceCalc/downloadMusteriImage/${_id}`, //your url
                                            method: 'GET',
                                            responseType: 'blob', // important
                                          }).then((response) => {
                                             const url = window.URL.createObjectURL(new Blob([response.data]));
                                             const link = document.createElement('a');
                                             link.href = url;
                                             link.setAttribute('download', 'file.wav'); //or any other extension
                                             document.body.appendChild(link);
                                             link.click();
                                          });*/
                                    }}>
                                        Foto indir
                                    </button>}
                                   {/* <audio controls="controls" autobuffer="autobuffer" style={{width:'100%'}}>
                                        <source src={config.apiUri+`/PriceCalc/downloadSound/${_id}`} />
                                    </audio>*/}
                                </td>
                                <td>
                                    <input value={items[0].buyerName} onChange={(e)=>{
                                        data[index].items[0].buyerName = e.target.value;
                                        this.setState(this.state)
                                    }}></input><button className="btn btn-success btn-sm" onClick={()=>{
                                        this.updateUserName(_id, items[0].buyerName)
                                    }}>Kaydet</button>
                                    {/*items.map((i,index)=><div><a href={config.apiUri+`/PriceCalc/downloadImg/${_id}/${index}/${this.state.brightness}`} target="_blank">{i.buyerName||'-'}</a></div>)*/}
                                </td>
                                <td>
                                {items.map((i,index)=><div><a onClick={()=>{setTimeout(() => {
                                    this.setState({brightness:1.2, textScale:1})
                                }, 1000); }} href={config.apiUri+`/PriceCalc/downloadImg/${_id}/${index}/${this.state.brightness}/${this.state.textScale}`} target="_blank">{i.topTitle||i.subTitle2}/({boyutlar[i.size]})</a></div>)}
                                </td>
                                <td>
                                {items[0].subTitle2}
                                </td>
                                <td>
                                {items.map((i,index)=><div><a onClick={()=>{setTimeout(() => {
                                    this.setState({brightness:1.2, textScale:1})
                                }, 1000); }} href={config.apiUri+`/PriceCalc/downloadQr/${_id}/${index}`} target="_blank">{boyutlar[i.size] ? 'Ses izi' : 'Plak'}</a></div>)}
                                </td>
                                <td><a href={`/CreateAndDownload?Id=${_id}&index=0${items[0].size=='tp'?'&type=music': ''}`} target='blank'>Düzenle ve İndir</a></td>
                            </tr>
                        })}
                    </tbody>
                </table>
                </div>
            </div>
        ); 
  }
  
}