import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Celestial from 'd3-celestial'
import '../../App.css'
import moment from 'moment'
import config from '../../config'
import $ from 'jquery'
import stages from './stages'
import StyleClass from './classMap.json'
import widths from './postersizes'
//import QRCode from 'qrcode.react';
//import QRCode from 'react-native-qrcode-svg';
import { QRCode } from "react-qr-svg";
//import ReactQr from 'react-awesome-qr'
const QrSizes = {
  "10x15":15,//18% toptitle = 3.8vw
  "13x18":14,//18%

  "25x34":10,//21x30 //13% //dış çerçeve orantısını 3% yap
  "34x44":9,//30x40 //11% //beyaz arka plan, kare tik i ekle
}
const SizePaddings = {
  "10x15":135,//15/10*100,//18% toptitle = 3.8vw
  "13x18":124.462,//18/13*100,//18%
  "tp": 139,
  "25x34":122,//34/25*100,//21x30 //13% //dış çerçeve orantısını 3% yap
  "34x44":118//44/34*100,//30x40 //11% //beyaz arka plan, kare tik i ekle
}
var Cel = null
export default class Starmap extends React.Component{
  constructor(props){
    super(props);
    
    this.state = {
      topTitle:'',
      firstTitle:'',
      showDate:true,
      //date: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
      currentStage:0,
      date:new Date(),
      location: {lat: 41.0082376, lng: 28.9783589},
      locationText: "İstanbul, Türkiye",
      size:'M',
      posterId:1,
      ...props.state,
      vaweIndex:Math.floor(Math.random() * 4)+1
    }
    this.qrRef = React.createRef();
    //console.log(moment(new Date()).format('YYYY-MM-DDTHH:mm'))
  }

  refreshWave = ()=>{
    this.setState({vaweIndex:Math.floor(Math.random() * 4)+1})
  }
  componentDidMount = ()=>{
    var {forOutput} = this.props;
    if(forOutput){
      
    }
    
    //document.getElementsByTagName('canvas')[0].className = "canvas"
    //console.log()
    this.updateStarMap()
    this.resize()
    window.addEventListener('resize', this.resize)
  }
  resize = ()=>{
    this.setState(this.state)
    console.log(this.qrRef)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  componentDidUpdate = () => {
    this.updateStarMap()
  }

  updateStarMap = ()=>{
   
  }

  calcHeight =()=>{
    var {posterId} = this.state
    var {size} = this.state;
    return $("#starmap"+posterId)[0]?Math.floor(21*($("#starmap"+posterId)[0].offsetHeight/$("#starmap"+posterId)[0].offsetWidth)):0
  }

  calcCrop = ()=>{
    var {crop} = this.props.state; 
    console.log(crop)
    if(!crop){
      return ''
    }
    console.log()
    return crop.translate
  }

  drawCanvas = ()=>{
    var {base64image, crop} = this.props.state
    var {forOutput, forQr} = this.props;
    var img = 'https://images.pexels.com/photos/556667/pexels-photo-556667.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
    if(base64image)img = base64image;
    setTimeout(() => {
      /*var canvas = document.getElementById("posterCanvas");
      var ctx = canvas.getContext("2d");
      ctx.fillStyle = "blue";
      ctx.fillRect(0, 0, canvas.width, canvas.height);*/
    }, 500);
    var canvas = document.getElementById("posterCanvas");
    if(!canvas){
      return
    }
    var context = canvas.getContext("2d");
    context.fillStyle = "black";
    context.fillRect(0, 0, canvas.width, canvas.height);
    var image = new Image();
    image.onload = function() {
      var wittth = image.naturalWidth;
      if(image.naturalHeight<image.naturalWidth){
        wittth = image.naturalHeight;
      }
      var centerX = image.naturalWidth/2
      var centerY = image.naturalHeight/2
      
      var startX = centerX-wittth/2;
      var endX = centerX+wittth/2;
      var startY = centerY-wittth/2;
      var endY = centerY+wittth/2;
      if(crop){
        context.drawImage(image,
          crop.x, crop.y,  //sx, sy
          crop.width, crop.height, //swidth, sheight
          0,0, //dx, dy
          forOutput?1500:400,forOutput?1500:400);//dwidth, dy
          console.log(
            crop.x, crop.y,  //sx, sy
            crop.width, crop.height, //swidth, sheight
            0,0, //dx, dy
            forOutput?1500:400,forOutput?1500:400)
      }else{
        context.drawImage(image, startX, startY, endX, endY,0, 0, forOutput?1500:400,forOutput?1500:400);
      }
      context.save()

      context.textBaseline = 'middle';
      context.textAlign = "center";
      context.fillStyle = "#ffffffd4";
      context.font = "oblique 50px Arial";
      
      context.translate( 200, 200 );
      context.globalAlpha = 1;
      //context.rotate((-45 * Math.PI / 180));
      if(!forOutput)context.fillText('qrmarkets.net' ,0, 0);
      context.restore();
      console.log('image has been drawn')
      

    };
    image.src = img;
  }

  drawStarMap = ()=>{
    var {topTitle,secondTopTitle,subTitle,subTitle2,siparisId,size, showDate, date,location,locationText,posterId, font,secretmsg, mask,base64image} = this.props.state; 
    var {forOutput, forQr} = this.props;
    var {posterId,vaweIndex} = this.state; 
    var fontratio = 0;
    var posterDiv = $(`#starmap${posterId}`)
    if(posterDiv){
      fontratio = (posterDiv[0]?posterDiv[0].offsetWidth:window.innerWidth)/window.innerWidth
    }
    this.drawCanvas()
    var fsScale = forOutput?4:4
    var canvSize = {position:'relative', width:'70%', marginLeft:'15%', marginTop:topTitle?'0%':'5.5%'}
    if(size == '10x15' ){
      fsScale = 5
      canvSize = {position:'relative', width:'80%', marginLeft:'10%', marginTop:topTitle?'0%':'1.5%'}
    }
    if(size == '13x18'){
      fsScale = 4
      canvSize = {position:'relative', width:'78%', marginLeft:'11%', marginTop:topTitle?'0%':'1.5%'}
    }
    if(size == '34x44'){
      fsScale = 4
    }
    if(mask == 'transparent_gift'){
      fsScale = 4
      canvSize = {position:'relative', width:'80%', marginLeft:'10%', marginTop: '7%'}
    }
    var brightness = 1.2
    const urlParams = new URLSearchParams(window.location.search);
    const b = urlParams.get('brightness');
    brightness = parseFloat(b);

    var textScale = 1;
    const t = urlParams.get('textScale');
    if(t){
      textScale = parseFloat(t);
    }
    
    //console.log(fontratio)
    var fs = `${fsScale*fontratio*textScale}vw`
    
    var fsb = `${fsScale*fontratio}vw`
    //console.log(base64image)
    if(subTitle2){
      var len = subTitle2.length/35;
      if(len>5)len=5;
      if(len>0)fs = `${(fsScale*fontratio*textScale)-.34*fontratio*len}vw`
      //console.log(fs)
      //console.log((fsScale*fontratio), .2*len)
    }
    var maskClass = StyleClass[mask]
    var logoUrl = maskClass.variant == 'dark'?'./vaweImages/white/logo.png':'./vaweImages/black/logo.png'
    
    var vaweUrl = maskClass.variant == 'dark'?`./vaweImages/white/${vaweIndex}.png`:`./vaweImages/black/${vaweIndex}.png`
    var beniDinle = maskClass.variant == 'dark'?`./vaweImages/white/beni Dinle.png`:`./vaweImages/black/beni Dinle.png`
    var QRFOTO = maskClass.variant == 'dark'?`./vaweImages/white/QR.png`:`./vaweImages/black/QR.png`
    
    var qrsize = QrSizes[size]
    //var cropStyle = this.calcCrop()
    if(mask == 'transparent_gift'){
      vaweUrl = './playimage.png'
    }
    return (<div className={`${maskClass.css} ${forOutput?'output':''} col-sm-12 col-md-12 col-lg-12`} style={forOutput?{filter:`brightness(${brightness})`,margin:'0px',width:'100vw', height:'100vh'}:{height:'100%'}} id={"starmap"+posterId}>
                <div className="innerstarmapContainer" style={forOutput?{height:'100%', borderWidth:'12px', }:{height:'100%'/*paddingBottom:'25%'*/}}>
                    {<h1 className={forOutput?"starmapTitles":"starmapTitles"}  style={topTitle?{fontFamily:font, marginTop:'1%', marginBottom:'2%', fontSize:`${3.8*fontratio}vw`}:{opacity:'.6', fontSize:fs}}>{mask != 'transparent_gift' ? topTitle||forOutput?topTitle:'' : ''}</h1>}
                    <div style={canvSize}>
                      
                      <div className={``}  style={{position:'relative',overflow:'hidden', top:0,paddingTop:'100%',opacity:'1', borderRadius:showDate?'0px':'0px', borderStyle:'none', width:'100%',  height:'100%'/*, backgroundImage:`url(${base64image||'https://images.pexels.com/photos/556667/pexels-photo-556667.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'})`*/}} >
                        {/*<div className={``} style={{position:'absolute',top:0,left:0,width:'100%', height:'calc( 100% + 1px)',paddingTop:'100%', borderRadius:showDate?'0px':'50%', backgroundImage:`url(${base64image||'https://images.pexels.com/photos/556667/pexels-photo-556667.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'})`}}></div>*/}
                        {<canvas className={`${mask} height100`} id={'posterCanvas'} height={forOutput?"1500":"400"} width={forOutput?"1500":"400"} style={{position:'absolute', width:'100%',height:'100%',borderRadius:showDate?'0px':'50%', marginTop:'-100%'}}></canvas>}
                        {/*<img style={{width:'100%', marginTop:'-100%', transform:cropStyle}} src={`${base64image||'https://images.pexels.com/photos/556667/pexels-photo-556667.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'}`}></img>*/}
                        {/*<div className={`${mask} bgCss`} style={{width:'100%', backgroundSize:'cover', height:'100%',paddingTop:'100%', borderRadius:showDate?'0px':'50%', backgroundImage:`url(${base64image||'https://images.pexels.com/photos/556667/pexels-photo-556667.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'})`}}></div>*/}
                      </div>
                    
                    
                    
                    </div>
                    
                    {<div className={forOutput?"hfc output":"hfc"} id={"celestial-map"+posterId} style={{display:'none',width:'100%',marginLeft:'auto',marginRight:'auto', height:"fit-content"}}>
                    
                    </div>}

                    {mask == 'transparent_gift' && <h1 className={"starmapTitles playtext"}  style={topTitle?{fontFamily:font, marginTop:'5%', fontWeight: 'bold', marginBottom:'-2%', fontSize:`${3.8*fontratio}vw`}:{opacity:'.6', fontSize:fs}}>{mask == 'transparent_gift' ? topTitle||forOutput?topTitle:'' : ''}</h1>}

                    {subTitle2 && mask == 'transparent_gift'?
                    subTitle2.split('\n').map(text=><h1 className={'playtext'} style={subTitle2?{fontFamily:font,fontSize:fs, textAlign:'center', marginTop: '2%', marginBottom:'-20%', width:size=='34x44'||size=='25x34'?'80%':'80%', marginLeft:size=='34x44'||size=='25x34'?'10%':'10%',padding:'0px'}:{opacity:'.6', fontSize:fs}}>{text}</h1>):mask == 'transparent_gift' ? <div style={{marginBottom:'-18%', paddingTop: '5%'}}></div> : null}
                    
                    
                    <img style={{width:mask == 'transparent_gift' ? '88%' : '80%', marginLeft:mask == 'transparent_gift' ? '6%' : '10%', marginTop:true?'8%':'5%', marginBottom:true?'8%':'5%'}} src={vaweUrl}></img>
                    
                    {/*<h1 className={forOutput?"starmapTitles":"starmapTitles"} style={subTitle?{/*fontFamily:font*/ /*fontSize:fs}:{opacity:'.6', fontSize:fs}}>{subTitle||forOutput?subTitle:'Üst Not'}</h1>*/}
                    {/*<h1 className={forOutput?"starmapTitles":"starmapTitles"} style={subTitle2?{fontFamily:font,fontSize:fs, textAlign:'center', width:'60%', marginLeft:'20%'}:{opacity:'.6', fontSize:fs}}>{subTitle2||forOutput?subTitle2:'Alt Not'}</h1>*/}
                    {subTitle2 && mask != 'transparent_gift'?
                    subTitle2.split('\n').map(text=><h1 className={forOutput?"starmapTitles":"starmapTitles"} style={subTitle2?{fontFamily:font,fontSize:fs, textAlign:'center', width:size=='34x44'||size=='25x34'?'80%':'80%', marginLeft:size=='34x44'||size=='25x34'?'10%':'10%',padding:'0px'}:{opacity:'.6', fontSize:fs}}>{text}</h1>):null}
                    {/*subTitle?<h1 className="starmapTitles">{subTitle}</h1>:null}
                    {subTitle2?<h1 className="starmapTitles">{subTitle2}</h1>:null*/}
                    <div style={{position:'absolute', bottom:'0px', left:'0px', width:'100%', paddingBottom:'4%'}}>
                      {/*locationText?<h1 className={forOutput?"starmapSubInfo starmapSubInfoOutput":"starmapSubInfo"} style={forOutput?{paddingTop:'10%', fontSize:fsb}:{fontSize:fsb}}>{locationText}</h1>:null}
                      {showDate?<h1 className={forOutput?"starmapSubInfo starmapSubInfoOutput":"starmapSubInfo"} style={{fontSize:fsb}}>{moment(date).format('DD/MM/YYYY HH:mm').replace(' 12:00','')}</h1>:null}
                      
                  {location?<h1 className={forOutput?"starmapSubInfo starmapSubInfoOutput":"starmapSubInfo"} style={{fontSize:fsb}}>{location.lat.toFixed(4)}°N {location.lng.toFixed(4)}°E</h1>:null*/}
                    </div>
                    {/*<img src="./vaweImages/Logo.png"></img>*/}
                    {forQr && <div style={{position: 'fixed', width: '100vw', height: '100vh', left:0, top: 0, zIndex: 300, backgroundColor: 'white', padding: '3%'}}><QRCode
                            bgColor="#FFFFFF"
                            fgColor="#000000"
                            level="L"
                            style={{ width: '100%' }}
                            value={siparisId?`${config.apiUri}/PriceCalc/Redirect/${siparisId}`:'Önce düzenleyin, sonra taratın'}
                        /></div>}
                    {mask != 'transparent_gift' && (forOutput ? <div style={{position:'absolute', bottom:'1%', left:'2%', width:qrsize?`${qrsize}%`:`15%`/*, backgroundColor:'white', padding:'0.2% 0.4% 0.2% 0.3%'*/, borderRadius:'5%'}}>
                      
                      <div style={{padding:'4%', backgroundColor:'white', /*borderRadius:'5%'*/}}><div style={{/*padding:'7%', backgroundColor:'black',*/ borderRadius:'5%'}}>
                        {<QRCode
                            bgColor="#FFFFFF"
                            fgColor="#000000"
                            level="L"
                            style={{ width: '100%' }}
                            value={siparisId?`${config.apiUri}/PriceCalc/Redirect/${siparisId}`:'Önce düzenleyin, sonra taratın'}
                        />}
                      </div></div>
                      <img src={beniDinle} style={{width:'100%', marginTop:'3%'}}></img>
                    </div>:<div style={{position:'absolute', bottom:'1%', left:'2%', width:qrsize?`${qrsize}%`:`15%`/*, backgroundColor:'white', padding:'0.2% 0.4% 0.2% 0.3%'*/, borderRadius:'5%'}}>
                      {<img src={QRFOTO} style={{width:'100%', marginTop:'3%'}}></img>}
                    </div>)}
                    <img src={logoUrl} style={{position:'absolute', bottom:'1%', right:'2%', width:'20%', padding:'1% 1.3% 1% 1%', borderRadius:'5%'}}>
                        
                    </img>
                    {mask == 'transparent_gift' && <div style={{position:'absolute', bottom:'25%', left:'8%', width:qrsize?`${qrsize}%`:`15%`/*, backgroundColor:'white', padding:'0.2% 0.4% 0.2% 0.3%'*/, borderRadius:'5%'}}>
                      
                      <div style={{padding:'4%', backgroundColor:'white', /*borderRadius:'5%'*/}}><div style={{/*padding:'7%', backgroundColor:'black',*/ borderRadius:'5%'}}>
                        {<QRCode
                            bgColor="#FFFFFF"
                            fgColor="#000000"
                            level="L"
                            style={{ width: '100%' }}
                            value={siparisId?`${config.apiUri}/PriceCalc/Redirect/${siparisId}`:'Önce düzenleyin, sonra taratın'}
                        />}
                      </div></div>
                    </div>}
                </div>
            </div>
            
            )
  }

  render(){
    var {size} = this.props.state
    //console.log(this.props)
    var {forOutput} = this.props;
    if(widths[size]){
      var width=parseFloat(widths[size].w).toFixed(0)
      var height=parseFloat(widths[size].h).toFixed(0)
    }else {
      var width='-'
      var height='-'
    }
    const whichpage = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    }).type
    var ptop = whichpage !== 'music' ? SizePaddings[size] : 139
    
    return (
        <div className={forOutput?"col-sm-12 col-md-12 col-lg-12":"col-sm-12 col-md-6 col-lg-5"} style={forOutput?{padding:'0px'}:{}}>
            {!forOutput?<div style={{position:'relative', width:'100%', marginBottom:'1rem'}}>
              <div style={{width:'100%', paddingTop:`${ptop}%`}}>
                <div style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}}>
                  {this.drawStarMap()}
                </div>
              </div>

            </div>:this.drawStarMap()}
            
            {/*<div className="sizeInfo">
                Boyut <br/> {width!=0?`${width}cm X ${height}cm`:`HD`}
            </div>*/}
        </div>
      ); 
  }
}

var getConfig = (data)=>{
    var {date,location,posterId} = data; 
    //console.log(location)
    var config = {
      width: 500,
      projection: "airy", 
      transform: "equatorial", 
      //center: [180,0,0],
      background: { width:0 }, 
      adaptable: true,    
      geopos:  location?[location.lat,location.lng]:[40.9667,29.1072],
      zoomlevel: 1.6,    // initial zoom level 0...zoomextend; 0|null = default, 1 = 100%, 0 < x <= zoomextend
      zoomextend: 100,  
      interactive: false, 
      form: true,
      location: true,
      fullwidth: false,
      controls: false,
      lang: "en",
      container: "celestial-map"+posterId,
      datapath: "../data/",  
      stars: {
        show: true,    //Show stars
        limit: 9,      //up to maximum stellar magnitude
        colors: false,  //Show stars spectral colors, if not use "color"
        style: { fill:"#ffffff", opacity:1 }, // Default style for stars
        names: false,   //Show star names 
        namestyle: { fill: "#ddddbb", font: "48px 'Palatino Linotype', Georgia, Times, 'Times Roman', serif", align: "left", baseline: "top" },
        proper: false, //Show proper names (if none shows designation)
        propernamestyle: { fill: "#ddddbb", font: "48px 'Palatino Linotype', Georgia, Times, 'Times Roman', serif", align: "right", baseline: "bottom" },
        desig: false,   //Show designation (Bayer, Flamsteed, Variable star, Gliese, Draper, Hipparcos, whichever applies first)
        namelimit: 5,   //Maximum magnitude with name
        propernamelimit: 2.5,   //Maximum magnitude with name
        size: 6,
        exponent: -0.26,
        data: "stars.6.json" // Data source for stellar data  
      },
      constellations: {
        show: true,    //Show constellations  
        names: false,   //Show constellation names 
        namestyle: { fill: "#cccccc", font: ["bold 0px 'Lucida Sans Unicode', Trebuchet, Helvetica, Arial, sans-serif", "bold 62px 'Lucida Sans Unicode', Trebuchet, Helvetica, Arial, sans-serif", "bold 56px 'Lucida Sans Unicode', Trebuchet, Helvetica, Arial, sans-serif"], align: "center", baseline: "middle" },
        desig: false,   //Show short constellation names (3 letter designations)
        lines: true,   //Show constellation lines 
        linestyle: { stroke: "#eeeeee", width: .7, opacity: 0.85 },
        bounds: false,  //Show constellation boundaries 
        boundstyle: { stroke: "#ccff00", width: 1, opacity: 0.8, dash: [4, 8] }
      },
      dsos: {
        show: false,    //Show Deep Space Objects
        limit: 16,      //up to maximum magnitude
        names: false,   //Show DSO names
        namestyle: { fill: "#cccccc", font: "0px 'Lucida Sans Unicode', Trebuchet, Helvetica, Arial, sans-serif", align: "left", baseline: "bottom" },
        desig: false,   //Show short DSO names
        namelimit: 16,   //Maximum magnitude with name
        size: 0,    
        exponent: 0,
        data: "dsos.bright.json"
      },
      mw: {
        show: false,    //Show Milky Way outlines
        style: { fill:"#ffffff", opacity:"0.03" }
      },
      lines: {//lines.graticule.width = 2
        graticule: { show: true, stroke:"#cccccc", width:1, opacity:1, // Show graticule lines 
        lon: {pos: ["center"], fill: "#eee", font: "0px 'Lucida Sans Unicode', Trebuchet, Helvetica, Arial, sans-serif"}, // grid values: "outline", "center", or [lat,...] specific position
        lat: {pos: ["outline"], fill: "#666", font: "0px 'Lucida Sans Unicode', Trebuchet, Helvetica, Arial, sans-serif"}  // grid values: "outline", "center", or [lon,...] specific position
        },
        equatorial: { show: false, stroke:"#aaaaaa", width:0, opacity:.7 }, // Show equatorial plane 
        ecliptic: { show: false, stroke:"#66cc66", width:0, opacity:.7 },   // Show ecliptic plane 
        galactic: { show: false, stroke:"#cc6666", width:0, opacity:.7 },  // Show galactic plane 
        supergalactic: { show: false, stroke:"#cc66cc", width:0, opacity:.7 } // Show supergalactic plane 
      }
    };
    //console.log(config)
    return config;
  }
  