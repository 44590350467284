import React, {Suspense} from "react";
import {
  Router,
  Switch,
  Route,
} from "react-router";
import {Navbar, Nav} from 'react-bootstrap'
//import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import './product.css'
import Footer from './components/footer'
import Home from './pages/home'
//import Ar from './pages/ar'

import CheckOut from './pages/checkout'
import { connect } from 'react-redux';

import AboutUs from './pages/aboutus'
import Contact from './pages/contact'
import Takip from './pages/takip'
import Login from './pages/login'
import Register from './pages/register'
import Product from './pages/product'
import Lithophane from './pages/litophane'
import ProductList from './pages/product list'
import history from './_constants/history'
import AddProduct from './pages/productAdd'
import EditProductList from './pages/productEditList'
import AdminPage from './pages/admin'
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
//import {Output} from './pages/starmap/output'
//Lazy Components
//import {Starmap} from './pages/starmap'
import {Starmap, CreateAndDownload} from './pages/starmap'
//import {Onizleme} from './pages/starmap/onizleme'
const Onizleme = React.lazy(() => import('./pages/starmap/onizleme').then(module => ({ default: module.Onizleme })));

const Output = React.lazy(() => import('./pages/starmap/output').then(module => ({ default: module.Output })));
const OutputQr = React.lazy(() => import('./pages/starmap/output Qr').then(module => ({ default: module.OutputQr })));
const LazyList = [/*{C:Starmap, p:'/StarMap'},{C:Starmap, p:'/'},*/ {C:Onizleme, p:'/Onizleme'}, {C:Output, p:'/Output'}, {C:OutputQr, p:'/OutputQr'}]
const Loading = <div style={{backgroundColor:'transparent', minHeight:'calc( 100vh - 149px)'}}>
  
  Yükleniyor

</div>
// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered). 
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.  

function BasicExample(props) {
  if(!window.location.href.includes('https:') && window.location.href.includes('qrmarkets')){
    window.location.href = window.location.href.replace('http:', 'https:')
  }
  var {Cart} = props;
  return (
    <Router history={history}>
      <div className='parallaxStars' style={{backgroundImage:'url(https://www.gokyuzun.com/images/space.jpg)', backgroundAttachment:'fixed', backgroundColor:'#f8f9fa'}}>
        <div style={{backgroundColor:'#f8f9fa', width:'100%'}}>
        <Navbar  className='container' variant="light"  expand="lg">
          <Navbar.Brand onClick={()=>{history.push('/')}} style={{fontSize:'15px', fontWeight:'600', color:'black', cursor:'pointer'}}><img style={{width:'82px'}} src="./vaweImages/black/logo.png"></img></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse variant="light" style={{color:'white'}} id="basic-navbar-nav ">
            <Nav className="mr-auto">
              {/*
              <Nav.Link href="/Ar">Link</Nav.Link>
              
              <Nav.Link href="/MapPoster">Harita Posteri</Nav.Link>*/}
              
              {/*<Nav.Link href="/">Yıldızınız İçin</Nav.Link> href="/StarMap"*/}
              <Nav.Link onClick={()=>{history.push('/')}} style={{/*color:'white'*/}}>Anasayfa</Nav.Link>
              <Nav.Link onClick={()=>{history.push('/QrCode?type=music')}} style={{/*color:'white'*/}}>Dijital Plak Ürünler</Nav.Link>
              <Nav.Link onClick={()=>{history.push('/QrCode')}} style={{/*color:'white'*/}}>Ses İzi Tablo</Nav.Link>
              {/*<Nav.Link onClick={()=>{history.push('/products')}} style={{color:'white'}}>Ürünlerimiz</Nav.Link>*/}
              <Nav.Link onClick={()=>{history.push('/hakkimizda')}} style={{/*color:'white'*/}}>Hakkımızda</Nav.Link>
              <Nav.Link onClick={()=>{history.push('/gallery')}} style={{/*color:'white'*/}}>Örneklerimiz</Nav.Link>
            </Nav>
            {/*<Nav.Link style={{position:'relative',color:'white', display:'flex' }} onClick={()=>{history.push('/CheckOut')}}>
              <i className="fas fa-shopping-cart" style={{fontSize:'12px', marginRight:'3px',marginTop:'7px', color:'#00000096'}}></i>
            {Cart.items.length>0?<div className='cartNum'>{Cart.items.length}</div>:Cart.items.length}</Nav.Link>*/}
            </Navbar.Collapse >
        </Navbar>
        </div>
      </div>
      <div style={{width:'100%', position:'relative'}}>
      <Route render={({location}) => (
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            timeout={300}
            classNames="fade"
          >
            <div className='parallaxStars noselect pageContainer' style={{backgroundColor:'rgb(234 234 222)', minHeight:'calc( 100vh - 149px)'}}>
              {/*<hr />*/}
              <Switch location={location}>
                {/*<Route exact path="/">
                  <Home />
                </Route>*/}
                {/*<Route exact path="/">
                  <Suspense fallback={Loading}>
                    <Component.C />
                  </Suspense>
                </Route>*/}
                <Route exact path="/hakkimizda">
                  <AboutUs />
                </Route>
                <Route exact path="/gallery">
                  <Contact />
                </Route>
                
                {/*<Route path="/Ar">
                  <Ar />
                </Route>*/}
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/QrCode">
                    <Starmap />
                </Route>
                <Route exact path="/CreateAndDownload">
                    <CreateAndDownload />
                </Route>
                
                {/*<Route path="/Onizleme">
                    <Onizleme />
                </Route>*/}
                
                <Route path="/EPL">
                  <EditProductList />
                </Route>
                <Route path="/editProduct">
                  <AddProduct />
                </Route>
                <Route path="/CheckOut">
                  <CheckOut />
                </Route>
                {/*<Route path="/Output">
                  <Output />
            </Route>*/}
                <Route path="/Takip">
                  <Takip />
                </Route>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/AdminPage">
                  <AdminPage />
                </Route>
                
                <Route path="/register">
                  <Register />
                </Route>
                {/*<Route path="/product">
                  <Product />
                </Route>
                <Route path="/lithophane">
                  <Lithophane />
                </Route>
                
                <Route path="/products">
                  <ProductList />
                </Route>*/}
                {/*<Suspense fallback={Loading}>
                    <Route path={'/StarMap'}>
                      <Starmap/>
                    </Route>
            </Suspense>*/}
                {LazyList.map(Component=>{
                  return ( 
                    <Route exact path={Component.p}>
                      <Suspense fallback={Loading}>
                        <Component.C />
                      </Suspense>
                    </Route>)
                })}
                
                
                
              </Switch>
              <h1 className="col-12 col-sm-12 kargoucretsiz" style={{textAlign:'center'}}> Kargo Ücretsizdir. Opsiyonu İşaretlemeniz durumunda siparişiniz hediye paketi içerisinde gönderilir. </h1>
            <Footer/>
            </div>
          </CSSTransition>
        </TransitionGroup> )} />
        </div>
    
    </Router>
  );
}

function mapState(state) {
 
  return state;
}

const connectedHomePage = connect(mapState)(BasicExample);
export default connectedHomePage;