import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../config'
//import loading from './loading'
class CheckOut extends React.Component{
  constructor(props){
    super(props);
    this.myRef = React.createRef();
    this.state={
        width:'0px',
        imgIndex: 0
    }
  }

  onMouseOver=(e, images)=>{
    if(images.length == 0)return;

    var {left, width} = this.myRef.current.getBoundingClientRect();
    
    var { clientX, clientY } = e;
    //console.log(width, clientX, left)
    //console.log( width/(clientX-left) )
    var len = images.length;
    var part = width/len;
    var n = (clientX-left)/part
    if(n<0){
        n=0
    }
    //console.log(parseInt(n))
    this.setState({width:(clientX-left)+'px', imgIndex:parseInt(n)})
  }

  render(){
    var {title,description, choices,price,stock, noteEnabled,images} = this.props.product
    //var {choice} = this.state
    var { options } = this.props;
    if(!options) options={};
    var {hideDesc,imgList} = options
    var {imgIndex}=this.state;
    return (
    <div class="col-12 col-sm-12 col-md-12 col-lg-12"  >
        <div style={{backgroundColor:'#ececec', borderRadius:'5px',}}>
        <div class="scaleOnHover bgtransition" ref={this.myRef}  onMouseMove={(e)=>this.onMouseOver(e,images)} style={{ marginTop:'1rem', borderRadius:'5px', borderStyle:'none', paddingTop:options.height?options.height:'110%', position:'relative', backgroundSize: 'cover', backgroundColor:'#ffffff00', backgroundImage:images[imgIndex]?(images[imgIndex]._id?`url(${config.apiUri+'/images/urunImages/'+images[imgIndex]._id}.jpg)`:`url(${images[imgIndex].base64})`):'url( ./images/prototype.jpeg )'}}>
            <div style={{width:'100%', height:'15px', top:'3px', position:'absolute'}}><div style={{ height:'15px', width:'fit-content', marginLeft:'auto', marginRight:'Auto', display:'flex'}}>
                {images.map((image, i)=><div style={{width:'18px', backgroundColor:i==imgIndex?'rgb(105 104 104)':'rgb(231 231 231)', height:'4px', marginLeft:'3px'}}></div>)}
                
            </div></div>
            {/*<img class="card-img-top" src="https://i.pinimg.com/originals/68/24/e0/6824e0db033e1e0120696035ec6beb58.jpg" style={{height:'300px', position:'absolute', width:'100%', height:'100%', left:'0px', top:'0px'}} alt="Card image cap"/>*/}
            {hideDesc?null:<div class="card-body" style={{cursor:'pointer',position:'absolute', bottom:'0px', borderRadius:'0px 0px 5px 5px', left:'0px', backgroundColor:'#000000ab', paddingTop:'3px', width:'100%', minHeight:'30%'}}>
                <h4 class="card-title" style={{fontSize:'.9rem', color:'white', textAlign:'center', width:"100%", color: 'white', marginBottom:'2px'}}>{title||"Ürün Başlığı"}</h4>
                <p class="card-text" style={{fontSize:'.8rem', width:"100%", color:'white'}}>{description||'Ürün ile ilgili bir takım açıklamalar vs...'}</p>
                <div class="row">
                    
                        <p class="btn btn-block" style={{position:'absolute', fontSize:'20px', fontWeight:'600', right:'3px', bottom:'3px', width:'fit-content', margin:'0px', color:'white'}}>{parseFloat(price).toFixed(2)}₺</p>
                    
                    
                        {/*<a href="#" class="btn btn-success btn-block" style={{position:'absolute', right:'3px', bottom:'3px', width:'fit-content'}}>Sepete Ekle</a>*/}
                    
                </div>
            </div>}
        </div>
        </div>
        {/*JSON.stringify(images)*/}<div style={{width:'100%', display:'flex', flexWrap:'wrap'}}>{imgList?images.map((image, i)=><div onClick={()=>{this.setState({imgIndex:i})}} class="col-3 col-sm-2" style={{padding:'2px'}}><div style={{cursor:'pointer', backgroundSize: 'cover', marginTop:'10px', borderRadius:'3px', width:'100%',paddingTop:'87%',position:'relative', backgroundImage:`url(${config.apiUri+'/images/urunImages/'+image._id}.jpg)`}}>
            {imgIndex!=i?<div style={{position:'absolute', top:'0px', left:'0px', backgroundColor:'rgb(255 255 255 / 90%)', width:'100%', height:'100%'}}></div>:null}
          </div></div>):null}</div>
        
      </div>)
  }
}

export default CheckOut;