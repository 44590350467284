import React from "react";
import Cropper from 'react-easy-crop'
class App extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            image: props.image,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 1,
        }
        
    }
    
    
    onCropChange = crop => { 
        //console.log(crop);
        this.setState({ crop })
    }
    
    onCropComplete = async (croppedArea, croppedAreaPixels) => {
        //console.log('complete')
        //console.log(croppedArea, croppedAreaPixels)
        var { onUpdate } = this.props
        window.crop=croppedAreaPixels;
        onUpdate({target:{name:"crop", value:{...croppedAreaPixels, zoom:this.state.zoom, translate:document.getElementsByClassName('reactEasyCrop_Image')[0].style.transform}}})
        
    }
    
    onZoomChange = zoom => {
        this.setState({ zoom })
    }
    
    render() {
        var { onUpdate,cancel } = this.props
        return (
        <div style={{position:'absolute', left:0, top:0, width:"100%", height:'100%', zIndex:300}}><div style={{position:'relative', width:'100%', height:'100%'}}><Cropper
            image={this.state.image}
            crop={this.state.crop}
            zoom={this.state.zoom}
            aspect={this.state.aspect}
            showGrid={false}
            cropShape="round"
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
        />
        <div style={{position:'absolute', right:'0px', bottom:'0px', width:'100%', padding:'0px 4px 4px 6px', borderRadius:'5px', backgroundColor:'transparent'}}>
        <div class="btn-group" role="group" aria-label="Basic example" style={{marginLeft:'calc( 50% - 42px )', marginBottom: '10px'}}>
            <button onClick={()=>{onUpdate({target:{name:"closeCropper", value:true}})}}  type="button" className="btn btn-success" aria-label="Close">
                Kaydet
            </button>
            <button type="button" onClick={()=>{cancel()}} className="btn btn-danger" aria-label="Close" >
                İptal
            </button>
        </div>
        
       
        </div>
        
        </div>
        
        </div>
        )
    }
}

export default App