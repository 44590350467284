import React from 'react';

export default class FileBase64 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
  }

  componentDidMount = ()=>{
    
  }

  handleChange(e) {

    // get the files
    let files = e.target.files;

    // Process each file
    var allFiles = [];
    for (var i = 0; i < files.length; i++) {

      let file = files[i];

      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {

        // Make a fileInfo Object
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000),// + ' kB',
          base64: reader.result,
          file: file,
        };

        // Push it to the state
        allFiles.push(fileInfo);

        // If all files have been proceed
        if(allFiles.length == files.length){
          // Apply Callback function
          //if(this.props.multiple) this.props.onDone(allFiles);
          //else 
          if(allFiles[0].size>10000){
            return alert("Lütfen 1.5 mb'dan daha düşük boyutlu bir dosya seçin!")
          }
          if(this.props.onName){
            this.props.onName(file.name)
          }
          this.props.onDone(allFiles[0]);
        }

      } // reader.onload

    } // for

  }

  render() {
    var {placeholder, format} = this.props
    return (
        <div className="custom-file">
            <input
                className="custom-file-input"
                type="file"
                accept={format?format:"*"/*"image/png, image/jpeg, .jpeg, .png"*/}
                value={null}
                onClick={()=>{
                  if(this.props.alert)
                  this.props.alert();this.props.onDone({base64:null});
                  if(this.props.onName)this.props.onName('')
                }}
                onChange={ this.handleChange.bind(this) }
                multiple={ this.props.multiple } />
            <label className="custom-file-label" for="validatedCustomFile" >{placeholder||"Fotoğraf seçmek için tıklayın..."}</label>
            <div className="invalid-feedback"></div>
        </div>
        
    );
  }
}

FileBase64.defaultProps = {
  multiple: false,
};