import React from "react";
import history from '../_constants/history'
import 'bootstrap/dist/css/bootstrap.min.css';
import ProductPreview from '../components/productPreview'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import axios from 'axios'
import config from '../config'
// import Swiper styles
import 'swiper/swiper-bundle.css';
SwiperCore.use([Autoplay]);
export default class Home extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      results:[]
    }
  }
  componentDidMount(){
    this.getProduct()
  }
  getProduct = async (pg)=>{
    if(!pg){
      pg={
        page:1,
        limit:9
      }
    }

    var {data} = await axios.post(config.apiUri+`/products/get?page=${pg.page}&limit=${pg.limit}`)
    if(data.err){
      return alert(data.err);
    }
    this.setState({...data,  page:pg.page, limit:pg.limit})
  }
  /*
  ./images/redmoonpreview.jpg" />
                                <input id="star" type="radio" name="bgcolor" checked={mask=='red_moon'} form="renkform" className="form-check-input" value="star"/>
                                <label for="star" className="form-check-label"></label>
                            </div>
                            <div onClick={()=>{this.onUpdate({target:{name:"mask", value:'half_moon'}})}} className="form-check form-check-inline h5 p-3 shadow-sm border rounded-lg maskSelect" >
                                <img src="./images/half-moonpreview.jpg"
  */
  render(){
    var {results} = this.state
    return (
        <div className='parallaxStars noselect' style={{backgroundImage:'url(./images/Red-gift-box-on-white-background-christmas-lights-bokeh.jpg)', position:'relative'}}>
          
          <div className='' style={{display:'flex',padding:'30px 5% 30px 5%', flexWrap:'wrap'}}>
            
            {[{
              img: './homeimages/FD33FB17-425D-4D1C-BFB3-AC65D74266F7.JPEG',
              url: '/QrCode?type=music'
              }, {
                img: './homeimages/713351BF-776F-4B6D-B007-09AF2834D123.JPEG',
                url: '/QrCode'
                }].map(img=><div onClick={()=>{history.push(img.url)}} className='col-12 col-sm-6 col-md-7 col-lg-7' style={{padding:'1%', marginLeft:'auto', marginBottom: '30px', marginRight:'auto'}}>
              <img className='scaleMoreOnHover '  src={img.img} style={{width:'100%'}}></img></div>)}
            {/*<div className='col-12 col-sm-12 col-md-4 col-lg-2' style={{padding:'1%'}}>{[ './images/half-moonpreview.jpg',].map(img=><div className='col-6 col-sm-6 col-md-12 col-lg-12' style={{padding:'0px', marginLeft:'auto', marginRight:'auto'}}>
              <img className='scaleMoreOnHover '  src={img} style={{width:'100%'}}></img></div>)}</div>*/}
            {/*['./images/ornek1.PNG', './images/ornek3.PNG'].map(img=><div className='col-6 col-sm-6 col-md-4 col-lg-2' style={{padding:'1%', marginLeft:'auto', marginRight:'auto'}}>
              <img className='scaleMoreOnHover '  src={img} style={{width:'100%'}}></img></div>)*/}
          </div>
          <div  style={{backgroundColor:'rgb(0 0 0 / 61%)', color:'white', width:'100%', marginBottom: '30px'}}>
            
            <div className='container' style={{fontSize:'1rem',  paddingTop:'2rem', paddingBottom:'2rem', display:'flex', flexWrap:'wrap'}}>
              
              {/*<p style={{ fontFamily:'sans-serif', fontSize:'17px', width: '100%', textAlign:'center'}}>
                Hediyelerimizi İnceleyin
            </p>*/}
              <div style={{width:'100%', alignItems:'center', textAlign:'center'}}>
                {/*<a onClick={()=>{history.push('/StarMap')}} style={{marginLeft:'auto', marginRight:'auto'}}>
                  <button class="btn btn-primary btn-lg col-sm-4">HEMEN DENEYİN</button>
            </a>*/}
              </div>
              <p className='col-sm-12 col-md-6 col-lg-6' style={{ fontFamily:'sans-serif', fontSize:'17px',marginTop:'30px', textAlign:'center'}}>
                <h4>Dijital Plak Nedir?</h4>
                Sizin için özel hazırlanan bu hediyeler, bize gönderdiğiniz fotoğraf ve şarkı linkleriyle 3mm kalınlığında kırılmaz plaklara basılarak hazırlanır. Ve size ulaştığında telefon kameranız ile okutulduğunda Sizin için anlam ifade eden şarkılar, en ölümsüz anlarınız ile buluşmuş olur.
              </p>
              <p className='col-sm-12 col-md-6 col-lg-6' style={{ fontFamily:'sans-serif', fontSize:'17px',marginTop:'30px', textAlign:'center'}}>
                <h4>Ses İzi Tablosu Nedir? </h4>
                İsterseniz minik bi sesli not ile isterseniz ikinize ait bir şarkınız ile isterseniz bir video ile oluşturulan QR kodu tablonuza ekliyoruz. Telefonunuzun kamerası ile qr kod okutulunca sesinize yada şarkınıza ulaşılıyor.

Böylelikle hediyenizi alan kişiyi tablonuzla birlikte sizin sesinizden oluşan bir not ile gülümsetmiş olacaksınız.
              </p>
              <div className='kargo2' style={{positino:'absolute'}}> Kargo Bedava</div>
            </div>
          </div>
          <div className='' style={{display:'flex',padding:'30px 5% 30px 5%', flexWrap:'wrap'}}>
          {['./homeimages/IMG-9838.jpg', './homeimages/IMG-9852.jpg'].map(img=><div className='col-6 col-sm-5 col-md-5 col-lg-4 mt-3' style={{padding:'1%', marginLeft:'auto', marginBottom: '30px', marginRight:'auto'}}>
              <img className='scaleMoreOnHover '  src={img} style={{width:'100%'}}></img></div>)}
              </div>
          <div className='col-sm-12 col-md-12 col-lg-12 parallaxStars' style={{display:'flex',padding:'5vh 0% 10vh 0%', flexWrap:'wrap',  }}>
         {/*<h1 style={{width:'100%', color:'white', textAlign:'center'}}>Diğer Ürünlerimiz</h1>*/}
         {/*<Swiper
            spaceBetween={0}
            slidesPerView={2}
            speed={1000}
            autoplay={{delay:3000}}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            className='col-sm-12 col-md-7 col-lg-6'
          >{/*images: [{_id: "5fb5458dd884466fbc0633ce"}, {_id: "5fb5458dd884466fbc0633cf"}] *//*}
            {results.map(r=><SwiperSlide > <div onClick={()=>{history.push('/Product?Id='+r._id)}} style={{width:'100%'}}>
                <ProductPreview product={r} options={{hideDesc:true}}></ProductPreview></div>
              </SwiperSlide>)}
             
         </Swiper>*/}
            
          </div>
            
        </div>
      ); 
  }
  
}