import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Iade from './iptaliade'

export default class Home extends React.Component{
    constructor(props){
        super(props);
        this.state={
            ShowIade:false
        }
    }
    
    
    render(){
        var {ShowIade} = this.state;
        return (
            <div class="aboutus-section" style={{ backgroundAttachment:'fixed', perspective:'150%', minHeight:'calc( 100vh - 149px)'}}>
                {ShowIade?<Iade onClose={()=>{this.setState({ShowIade:false})}}/>:null}
                <div class="container" style={{backgroundColor:'white', marginTop:'60px', borderRadius:'10px'}}>
                    <div class="row" style={{paddingTop:'10vh',paddingBottom:'10vh'}}>
                        <div class="col-md-8 col-sm-12 col-xs-12">
                            <div class="aboutus col-md-8 offset-md-2 col-sm-12 col-xs-12">
                                <h5 class="aboutus-title">Hakkımızda </h5>
                                <p class="aboutus-text" style={{fontWeight:'500'}}>Türkiyenin ilk QR Hediye sayfasına HOŞ GELDİNİZ.   </p>
                                <p class="aboutus-text">İsterseniz minik bi sesli not ile isterseniz sizin için özel bir şarkı ile isterseniz bir video ile oluşturulan KAREKODLARI tablonuza ekliyoruz. </p>
                                <p class="aboutus-text">Ayrıca ses dosyalarınızdaki sesinizin titreşimleriyle oluşturulan ses dalgalarınızı da tablonuza ekleyerek eşsiz hediyeler haline getiriyoruz.  </p>
                                <p class="aboutus-text">Siz Telefonunuzun kamerası ile bu karekodları okutunca sesinize yada şarkınıza ulaşılacak. Böylelikle hediyenizi alan kişiyi tablonuzla birlikte sizin sesinizden oluşan bir not ile gülümsetmiş olacaksınız. </p>
                                <p class="aboutus-text">Qr kod iPhone’larda kamera üzerinden, Androidlerde ise google lens üzerinden dinlenebilmektedir. Google lens uygulamasını indirmenize gerek yoktur. Okutamadığınız durumlarda Qrscanner uygulamasını indirerek okutabilirsiniz. 
</p>
                                <p class="aboutus-text">Farklı renk, boyut gibi seçenekler ile tamamen kişiye özeldir.
</p>
                                <p class="aboutus-text">Ses izi tablolarınız IKEA marka çerçeve içerisinde gelir.
</p>
                                <p class="aboutus-text">İsterseniz “KENDİM BASTIRACAĞIM” seçeneği ile yüksek çözünürlüklü ses izi tablolarınızı e-mail adresinizden indirip basabilirsiniz.
</p>
                                <p class="aboutus-text">Siparişleriniz 2-3 iş günü içerisinde kargoya verilir.
</p>
                                <p class="aboutus-text">Gönderimlerimiz Yurtiçi Kargo ile yapılmaktadır. Sipariş verdiğiniz konumda Yurtiçi Kargonun çalıştığından emin olun.
</p>
                                <p class="aboutus-text">Hatalı işlemler için bizimle iletişime geçebilirsiniz.
</p>
                                <p class="aboutus-text">Belirtilen hususlar dışında herhangi bir olumsuz durum karşısında iade etme hakkına sahipsiniz. İade ve sorunlarınız için Whatsapp yoluyla iletişime geçebilirsiniz. En kısa sürede size cevap verilecektir.
</p>
                                <p class="aboutus-text">Wp: 0(551)1349238 
</p>
                                <p class="aboutus-text">e-mail: basimhanem01@gmail.com</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-12 col-xs-12">
                            <div class="aboutus-banner" style={{padding:'5%'}}>
                                <img id='imagetoanimate' className='imgShadow' src="/vaweImages/qr.png" style={{width:'100%', transformStyle:'preserve-3d'}} alt=""/>
                            </div>
                        </div>
                        {/*<div class="col-md-5 col-sm-6 col-xs-12">
                            <div class="feature">
                                <div class="feature-box">
                                    <div class="clearfix">
                                        <div class="iconset">
                                            <span class="glyphicon glyphicon-cog icon"></span>
                                        </div>
                                        <div class="feature-content">
                                            <h4>Work with heart</h4>
                                            <p>Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="feature-box">
                                    <div class="clearfix">
                                        <div class="iconset">
                                            <span class="glyphicon glyphicon-cog icon"></span>
                                        </div>
                                        <div class="feature-content">
                                            <h4>Reliable services</h4>
                                            <p>Donec vitae sapien ut libero venenatis faucibu. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="feature-box">
                                    <div class="clearfix">
                                        <div class="iconset">
                                            <span class="glyphicon glyphicon-cog icon"></span>
                                        </div>
                                        <div class="feature-content">
                                            <h4>Great support</h4>
                                            <p>Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        ); 
  }
  
}