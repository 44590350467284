import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

class CheckOut extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        
    }
  }



  render(){
      var {onClose}= this.props
    return (
        <div class="lightbox noselect col-sm-12 col-md-12 col-lg-12" style={{position:'absolute'}} onClick={(e)=>{if(!e.cancelBubble)onClose();e.cancelBubble=false;}}>
            <div class="content card col-sm-10 col-md-10 col-lg-8 offset-lg-2 offset-md-1" style={{height:'90%', padding:'0px'}} onClick={(e)=>{e.cancelBubble=true; console.log('ic div')}}>
            <div class="card-header">
                MESAFELİ SATIŞ SÖZLEŞMESİ
                <button type="button" class="close" onClick={()=>onClose()} aria-label="Close" style={{marginRight:'6px'}}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <p id="mesafelisatiss" className='mesafelisatis' style={{height:'100%', overflow:'auto', padding:'30px'}} >
                
                <h1></h1>
                <p>MESAFELİ SATIŞ SÖZLEŞMESİ
    1- KONU:
    İşbu sözleşmenin konusu, ALICI’nın SATICI’ya ait https://www.gokyuzun.com/ internet sitesinden elektronik
    ortamda siparişini yaptığı satış ücreti belirtilen ürünün/ürünlerin satışı ve teslimi ile ilgili olarak 4077
    sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmeleri Uygulama Esas ve Usulleri
    Hakkında Yönetmelik hükümleri gereğince tarafların hak ve yükümlülüklerinin saptanmasıdır.
    ALICI, SATICININ isim, unvan, açık adres, telefon ve diğer erişim bilgileri, satışa konu malın temel
    nitelikleri, vergiler dahil olmak üzere satış fiyatı, ödeme sekli, teslimat koşulları ve masrafları vs. satışa
    konu mal ile ilgili tüm ön bilgiler ve “cayma” hakkının kullanılması ve bu hakkın nasıl kullanılacağı,
    şikayet ve itirazlarını iletebilecekleri resmi makamlar vs. konusunda açık , anlaşılır ve internet ortamına
    uygun şekilde SATICI tarafından bilgilendirildiğini , bu ön bilgileri elektronik ortamda teyit ettiğini ve
    sonrasında mal sipariş verdiğini iş bu sözleşme hükümlerince kabul ve beyan eder.
    https://www.gokyuzun.com/ sitesinde yer alan ön bilgilendirme ve ALICI tarafından verilen sipariş üzerine
    düzenlenen/düzenlenecek fatura iş bu sözleşmenin ayrılmaz parçalarıdır
    2- SÖZLEŞME KONUSU ÜRÜN/ÖDEME/TESLİMAT BİLGİLERİ
    Elektronik ortamda alınan/alınacak ürün/ürünlerin cinsi ve türü, miktarı, marka/modeli, satış bedeli,
    ödeme şekli, teslim alacak kişi, teslimat adresi, fatura bilgilerinin girilmesi ALICI’nın sorumluluğunda
    olup, satın alınan ürün veya ürünlerin kargo ile teslimine ilişkin ücreti ALICI tarafından karşılanacaktır.
    Fatura edilecek kişi/kurum ile sözleşmeyi yapan kişi/kurum aynı olmak zorundadır.ALICI tarafından
    girilen tüm bilgiler doğru ve eksiksiz olmalıdır. Bu bilgilerin doğru olmadığı veya noksan olduğu
    durumlardan doğacak zararları tamamıyla karşılamayı ALICI kabul eder ve ayrıca bu durumdan
    oluşabilecek her türlü sorumluluğu ALICI kabul eder.
    SATICI gerekli gördüğü durumlarda, ALICI’nın vermiş olduğu bilgiler gerçekle örtüşmediğinde, siparişi
    durdurma hakkını saklı tutar. SATICI siparişte sorun tespit ettiği durumlarda ALICI’nın vermiş olduğu
    telefon, e-posta ve posta adreslerinden ALICI’ya ulaşamadığı takdirde siparişin yürürlüğe koyulmasını
    7 iş günü süreyle dondurur. ALICI’nın bu süre zarfında SATICI ile konuyla ilgili olarak iletişime geçmesi
    beklenir. Bu süre içerisinde ALICI’dan herhangi bir cevap alınamazsa SATICI, her iki tarafın da zarar
    görmemesi için siparişi iptal eder
    3- SÖZLEŞME TARİHİ VE MÜCBİR NEDENLER
    Sözleşme tarihi, ALICI tarafından siparişin verildiği tarihdir.
    Sözleşmenin imzalandığı tarihte mevcut olmayan veya öngörülmeyen, tarafların kontrolleri dışında
    gelişen, ortaya çıkmasıyla taraflardan birinin ya da her ikisinin de sözleşme ile yüklendikleri borç ve
    sorumluluklarını kısmen ya da tamamen yerine getirmelerini ya da bunları zamanında yerine
    getirmelerini olanaksızlaştıran durumlar, mücbir sebep (Doğal afet, savaş, terör, ayaklanma, değişen
    mevzuat hükümleri, el koyma veya grev, lokavt, üretim ve iletişim tesislerinde önemli ölçüde arıza vb.)
    olarak kabul edilecektir. Mücbir sebep şahsında gerçekleşen taraf, diğer tarafa durumu derhal ve yazılı
    olarak bildirecektir.
    Mücbir sebebin devamı esnasında tarafların edimlerini yerine getirememelerinden dolayı herhangi bir
    sorumlulukları doğmayacaktır. İşbu mücbir sebep durumu 15 gün süreyle devam ederse, taraflardan
    her birinin, tek taraflı olarak fesih hakkı doğmuş olacaktır.
    4- SATICININ HAK VE YÜKÜMLÜLÜKLERİ
    4.1. SATICI, 4077 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmelere Dair
    Yönetmelik hükümleri uyarınca sözleşmede kendisine yüklenen edimleri mücbir haller dışında eksiksiz
    yerine getirmeyi kabul ve taahhüt eder.
    4.2. 18 (on sekiz) yaşından küçük kişiler https://www.gokyuzun.com/ alışveriş yapamaz. SATICI, ALICININ
    veya ALICININ yetkilisinin sözleşmede belirttiği doğum tarihinin doğru olduğunu esas alacaktır. Ancak
    ALICININ doğum tarihini yanlış yazmasından dolayı SATICIYA hiçbir şekilde sorumluluk
    yüklenemeyecektir.
    4.3. SATICI, internet sitesindeki sistemden, dizayndan veya yasadışı yollarla internet sitesine
    yapılabilecek müdahaleler sebebiyle ortaya çıkabilecek tanıtım, fiyat hatalarından sorumlu değildir.
    Sistem hatalarına dayalı olarak ALICI, SATICIDAN hak iddiasında bulunamaz.
    4.4. https://www.gokyuzun.com/ kredi kartı (Visa, MasterCard , vs. ) ile alışveriş yapılabilir. Siparişlerin
    işleme alınma zamanı, siparişin verildiği an değil, kredi kartı hesabından gerekli tahsilâtın yapıldığı
    andır. Ödemeli gönderi ya da posta çeki gibi müşteri hizmetleri ile görüşülmeden gerçekleştirilen
    ödeme yöntemleri kabul edilmez.
    5- ALICININ HAK VE YÜKÜMLÜLÜKLERİ:
    5.1. ALICI, sözleşmede kendisine yüklenen edimleri mücbir sebepler dışında eksiksiz yerine getirmeyi
    kabul ve taahhüt eder.
    5.2. ALICI, sipariş vermekle birlikte iş sözleşme hükümlerini kabul etmiş sayıldığını ve sözleşmede
    belirtilen ödeme şekline uygun ödemeyi yapacağını kabul ve taahhüt eder.
    5.3. ALICI, https://www.gokyuzun.com/ internet sitesinden SATICININ isim, unvan, açık adres, telefon ve
    diğer erişim bilgileri, satışa konu malın temel nitelikleri, vergiler dahil olmak üzere satış fiyatı , ödeme
    sekli, teslimat koşulları ve masrafları vs. satışa konu mal ile ilgili tüm ön bilgiler ve “cayma” hakkının
    kullanılması ve bu hakkın nasıl kullanılacağı , şikayet ve itirazlarını iletebilecekleri resmi makamlar vs.
    konusunda açık , anlaşılır ve internet ortamına uygun şekilde bilgi sahibi olduğunu bu ön bilgileri
    elektronik ortamda teyit ettiğini kabul ve beyan eder.
    5.4. Bir önceki maddeye bağlı olarak ALICI, ürün sipariş ve ödeme koşullarının, ürün kullanım
    talimatlarının, olası durumlara karşı alınan tedbirlerin ve yapılan uyarıların olduğu
    sipariş/ödeme/kullanım prosedürü bilgilerini okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli
    teyidi verdiğini beyan eder.
    5.5. ALICI, aldığı ürünü iade etmek istemesi durumunda her ne surette olursa olsun ürüne ve
    ambalajına zarar vermemeyi, iade anında fatura aslını ve irsaliyesini iade etmeyi kabul ve taahhüt
    eder.
    5.6 ALICI, işbu sözleşmeden doğan hak, mükellefiyet ve borçlarını SATICININ yazılı izni olmaksızın
    üçüncü kişilere ve kuruluşlara devir ve temlik edemez.
    5.7 ALICI, işbu sözleşmeden doğacak ihtilaflarda SATICININ defter, belge ve kayıtlarını HMK 193
    anlamında kesin delil olacağını kabul ve taahhüt eder.
    6-SİPARİŞ VE ÖDEME PROSEDÜRÜ:
    Sipariş:
    Alışveriş sepetine eklenen ürünlerin KDV dahil TL tutarı (Taksitli işlemlerde toplam taksit tutarları)
    ALICI tarafından onaylandıktan sonra, ilgili banka kartının posu üzerinden işleme alınır. Bu nedenle
    siparişler, sevk edilmeden önce ALICIYA sipariş onay maili gönderilir. Sipariş Onay maili
    gönderilmeden sevkiyat yapılmaz.
    Süreçteki herhangi bir aksama durumu ya da kredi kartı ile ilgili ortaya çıkabilecek problemler ALICIYA
    sözleşmede belirttiği telefon/faks/e-mail yollarından biri veya bir kaçı kullanılmak sureti ile bildirilir.
    Gerekirse ALICIDAN bankası ile görüşmesi istenebilir. Siparişlerin işleme alınma zamanı, siparişin
    verildiği an değil, kredi kartı hesabından gerekli tahsilâtın yapıldığı andır.
    İstisnai olarak haklı bir nedenle sözleşme konusu malın tedarik edilemeyeceğinin anlaşılması ve/veya
    stok problemi ile karşılaşılması durumunda ALICI hemen açık ve anlaşılır bir şekilde bilgilendirilip onay
    vermesi durumunda ALICIYA eşit kalitede ve fiyatta başka bir mal gönderilebilir ya da ALICININ arzusu
    ve seçimi doğrultusunda ; yeni başka bir ürün gönderilebilir, ürünün stoklara girmesi ya da teslime
    engel diğer engelin ortadan kalkması beklenebilir ve/veya sipariş iptal edilebilir.
    Sözleşme konusu malın teslim yükümlülüğünün yerine getirilmesinin imkânsızlaştığı hâllerde ALICI bu
    durumdan haberdar edilerek ödemiş olduğu toplam bedel ve varsa onu borç altına sokan her türlü
    belge en geç 10 gün içinde kendisine iade edilerek sözleşme iptal edilir. Böyle bir durumda ALICININ
    SATICIDAN ilave herhangi bir maddi ve manevi zarar talebi olmayacaktır.
    SATICI ALICI ve/veya 3. şahıslara karşı işbu sözleşme ile bağlantılı olarak ifa ettiği işlerle ilgili dolaylı
    zararlardan hiçbir suretle sorumlu olmayacaktır.
    Ödeme:
    Ürünün tesliminden sonra ALICIYA ait kredi kartının ALICININ kusurundan kaynaklanmayan bir şekilde
    yetkisiz kişilerce haksız veya hukuka aykırı olarak kullanılması nedeni ile ilgili banka veya finans
    kuruluşun ürün bedelini SATICIYA ödememesi halinde, ALICININ kendisine teslim edilmiş ürünü 3 iş
    günü içinde SATICIYA göndermesi zorunludur. Bu tür durumlarda nakliye giderleri ALICIYA aittir.
    ALICI kredi kartı ile ödeme yapmayı tercih etmiş ise ALICI, ilgili faiz oranlarını ve temerrüt faizi ile ilgili
    bilgileri bankasından ayrıca teyit edeceğini, yürürlükte bulunan mevzuat hükümleri gereğince faiz ve
    temerrüt faizi ile ilgili hükümlerin Banka ve ALICI arasındaki “Kredi Kartı Sözleşmesi” kapsamında
    uygulanacağını kabul, beyan ve taahhüt eder.
    7-SİPARİŞ VE ÖDEME PROSEDÜRÜ
    Sevkiyat:
    Sipariş onayı mailinin gönderilmesiyle birlikte, ürün/ürünler SATICININ anlaşmalı olduğu kargo
    Şirketine verilir.
    Teslimat:
    Ürün/ürünler SATICININ anlaşmalı olduğu kargo ile ALICININ adresine teslim edilecektir. Teslimat
    süresi, Sipariş onayı mailinin gönderilmesinden ve sözleşmenin kurulmasından itibaren 3 iş günüdür.
    ALICIYA önceden yazılı olarak veya bir sürekli veri taşıyıcısıyla bildirilmek koşuluyla bu süre en fazla 10
    iş günü uzatılabilir
    Ürünler, Kargo şirketlerinin adres teslimatı yapmadığı bölgelere telefon ihbarlı olarak gönderilir.
    Kargo Şirketinin haftada bir gün teslimat yaptığı bölgelerde, sevk bilgilerindeki yanlışlık ve eksiklik
    olduğu hallerde, bazı sosyal olaylar ve doğal afetler gibi durumlarda belirtilen gün süresinde sarkma
    olabilir. Bu sarkmalardan dolayı ALICI SATICIYA herhangi bir sorumluluk yükleyemez. Ürün, ALICIDAN
    başka bir kişi/kuruluşa teslim edilecek ise, teslim edilecek kişi/kuruluşun teslimatı kabul
    etmemesinden, sevk bilgilerindeki yanlışlık ve/veya ALICININ yerinde olmamasından doğabilecek
    ekstra kargo bedellerinden SATICI sorumlu değildir. Belirtilen günler içeriğinde ürün/ürünler ALICIYA
    ulaşmadıysa teslimat problemleri SATICI müşteri hizmetlerine eleventhhediye@gmail.com adresi kullanılmak
    sureti ile derhal bildirilmelidir.
    Zarar görmüş paket durumunda; Zarar görmüş paketler teslim alınmayarak Kargo Şirketi yetkilisine
    tutanak tutturulmalıdır. Eğer Kargo Şirketi yetkilisi paketin hasarlı olmadığı görüşünde ise, paketin
    orada açılarak ürünlerin hasarsız teslim edildiğini kontrol ettirme ve durumun yine bir tutanakla tespit
    edilmesini isteme hakkı ALICIDA vardır. Paket ALICI tarafından teslim alındıktan sonra Kargo Şirketinin
    görevini tam olarak yaptığı kabul edilmiş olur. Paket kabul edilmemiş ve tutanak tutulmuş ise, durum,
    tutanağın ALICIDA kalan kopyasıyla birlikte en kısa zamanda SATICI Müşteri Hizmetlerine
    bildirilmelidir.
    8-ÜRÜN İADE VE CAYMA HAKKINA İLİŞKİN PROSEDÜR
    ALICI, malı teslim aldıktan sonra yedi gün içerisinde herhangi bir gerekçe göstermeksizin ve cezai şart
    ödemeksizin sözleşmeden cayma hakkına sahiptir. 385 sayılı vergi usul kanunu genel tebliği uyarınca
    iade işlemlerinin yapılabilmesi için ALICININ mal ile birlikte teslim edilen SATICIYA ait 2 adet faturanın
    alt kısmındaki iade bölümlerini eksiksiz ve doğru şekilde doldurduktan sonra imzalayarak bir nüshasını
    ürün ile birlikte SATICIYA göndermesi diğer nüshasını da uhdesinde tutması gerekmektedir.Cayma
    hakkı süresi ALICIYA malın teslim edildiği günden itibaren başlar. İade edilen ürün veya ürünlerin geri
    gönderim bedeli ALICI tarafından karşılanır.
    ALICININ cayma hakkını kullanması halinde SATICI, cayma bildirimini içeren faturanın ürünle birlikte
    kendisine ulaşmasından itibaren en geç 7 gün içerisinde almış olduğu toplam bedeli ve varsa ALICIYI
    borç altına sokan her türlü belgeyi ALICIYA hiçbir masraf yüklemeden iade edecektir
    Teslim alınmış olan malın değerinin azalması veya iadeyi imkânsız kılan bir nedenin varlığı cayma
    hakkının kullanılmasına engel değildir. Ancak değer azalması veya iadenin imkânsızlaşması ALICININ
    kusurundan kaynaklanıyorsa SATICIYA malın değerini veya değerindeki azalmayı tazmin edecektir.
    Sehven alınan her ürün için de genel iade süresi 7 gündür. Bu süre içerisinde, ambalajı açılmış,
    kullanılmış, tahrip edilmiş vesaire şekildeki ürünlerin iadesi kabul edilmez. İade, orijinal ambalaj ile
    yapılmalıdır.
    Sehven alınan üründe ve ambalajında herhangi bir açılma, bozulma, kırılma, tahrip, yırtılma, kullanılma
    ve sair durumlar tespit edildiği hallerde ve ürünün ALICIYA teslim edildiği andaki hali ile iade
    edilememesi durumunda ürün iade alınmaz ve bedeli iade edilmez.
    Ürün iadesi için, durum öncelikli olarak SATICI müşteri hizmetlerine iletilmelidir. Ürünün iade olarak
    gönderilme bilgisi, SATICI tarafından ALICIYA iletilir. Bu görüşmeden sonra ürün iade ile ilgili bilgileri
    içeren fatura ile birlikte ALICI adresine teslimatı yapan Kargo şirketi kanalıyla SATICIYA ulaştırmalıdır.
    SATICIYA ulaşan iade ürün iş bu sözleşmede belirtilen koşulları sağladığı takdirde iade olarak kabul
    edilir, geri ödemesi de ALICI kredi kartına/hesabına yapılır. Ürün iade edilmeden bedel iadesi yapılmaz.
    Kredi Kartına yapılan iadelerin kredi kartı hesaplarına yansıma süresi ilgili bankanın tasarrufundadır.
    Bundan dolayı ALICI, SATICIYI sorumlu tutamaz.
    Kart ile alınmış mal ve hizmetin iadesi durumunda SATICI, Banka ile yapmış olduğu sözleşme gereği
    ALICIYA nakit para ile ödeme yapamaz. Üye işyeri yani SATICI, bir iade işlemi söz konusu olduğunda
    ilgili yazılım aracılığı ile iadesini yapacak olup, üye işyeri yani SATICI ilgili tutarı Bankaya nakden veya
    mahsuben ödemekle yükümlü olduğundan yukarıda detayları belirtilen prosedür gereğince ALICIYA
    nakit olarak ödeme yapılamamaktadır. Kredi kartına iade, ALICININ Bankaya bedeli tek seferde
    ödemesinden sonra, Banka tarafından yukarıdaki prosedür gereğince yapılacaktır.
    9-GARANTİ
    Kullanma talimatına uygun şekilde kullanılan ürünler her türlü üretim hatasına karşı aşağıda belirtilen
    şartlar dahilinde 2 yıl garantilidir: SATICININ garanti sorumluluğu yalnızca 4077 sayılı kanun
    kapsamına giren tüketiciler için geçerlidir. Ticari nitelikteki işler için Türk Ticaret Kanununu hükümleri
    geçerli olacaktır.
    10-GİZLİLİK
    ALICI tarafından iş bu sözleşmede belirtilen bilgiler ile ödeme yapmak amacı ile SATICIYA bildirdiği
    bilgiler SATICI tarafından 3. şahıslarla paylaşılmayacaktır. SATICI bu bilgileri sadece idari/ yasal
    zorunluluğun mevcudiyeti çerçevesinde açıklayabilecektir. Araştırma ehliyeti belgelenmiş her türlü adli
    soruşturma dahilinde SATICI kendisinden istenen bilgiyi elinde bulunduruyorsa ilgili makama
    sağlayabilir.
    Kredi Kartı bilgileri kesinlikle saklanmaz,Kredi Kartı bilgileri sadece tahsilat işlemi sırasında ilgili
    bankalara güvenli bir şekilde iletilerek provizyon alınması için kullanılır ve provizyon sonrası sistemden
    silinir.
    ALICIYA ait e-posta adresi, posta adresi ve telefon gibi bilgiler yalnızca SATICI tarafından standart
    ürün teslim ve bilgilendirme prosedürleri için kullanılır. Bazı dönemlerde kampanya bilgileri, yeni
    ürünler hakkında bilgiler, promosyon bilgileri ALICIYA onayı sonrasında gönderilebilir.
    11- UYUŞMAZLIK DURUMUNDA YETKİLİ MAHKEME
    İşbu sözleşmenin uygulanmasından kaynaklanan uyuşmazlık halinde, Sanayi ve Ticaret Bakanlığınca
    her yıl Aralık ayında ilan edilen değere kadar Tüketici Hakem Heyetleri ile ALICININ veya SATICININ
    yerleşim yerindeki Tüketici Mahkemeleri yetkilidir.
    Siparişin gerçekleşmesi durumunda Alıcı işbu sözleşmenin tüm koşullarını kabul etmiş sayılır.</p>
                </p>
            </div>

        </div>
    ); 
  }
}

export default CheckOut;