//import { ReactMic } from 'react-mic';
import React from 'react'
//import Recorder from 'recorderjs'



import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
 
export default class App extends React.Component {
  constructor(props) {
    super(props)
 
    this.state = {
      recordState: null
    }
  }
 
  start = () => {
    var {onUpdate} = this.props;
    onUpdate(null)
    this.props.onName('')
    this.setState({
      recordState: RecordState.START,
      record:true
    })
  }
 
  stop = () => {
    this.setState({
      recordState: RecordState.STOP,
      record:false
    })
  }
 
  //audioData contains blob and blobUrl
  onStop = (recordedBlob) => {
    console.log('audioData', recordedBlob)
    var {onUpdate} = this.props;
    var reader = new FileReader();
    reader.readAsDataURL(recordedBlob.blob); 
    reader.onloadend = () => {
        var base64data = reader.result; 
        
        console.log(base64data)
        if(recordedBlob.blob.size>50){
          onUpdate(base64data)
          this.setState({blob:base64data})  
          setTimeout(() => {
            this.refs.audio.pause();
            this.refs.audio.load();
            this.refs.audio.play();
          }, 500);
        }
                    
        //console.log(base64data);
    }
  }
 
  render() {
    const { recordState,record } = this.state
    var {base64Sound, filename} = this.props;
    var blob = base64Sound
    console.log(blob)
    return (
      <div>
        <div style={{display:'none'}}><AudioReactRecorder state={recordState} onStop={this.onStop} /></div>
 
        {blob?<audio controls="controls" style={{/*display:filename?'none':'',*/width:'100%'}} ref="audio" autobuffer="autobuffer" autoPlay="autoplay">
                <source src={blob} />
            </audio>:null}
            <h1>{filename}</h1>
        <div class="btn-group" role="group" aria-label="Basic example">
          {!record?<button type="button" onClick={this.start} class="btn btn-success">Ses Kaydet</button>:
          <button type="button" onClick={this.stop} class="btn btn-danger">Durdur</button>}    
        </div>
        
      </div>
    )
  }
}







/*export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: false
    }
  }
 
  startRecording = () => {
    var {onUpdate} = this.props;
    onUpdate(null)
    this.setState({ record: true });
  }
 
  stopRecording = () => {
    this.setState({ record: false });
  }
 
  onData(recordedBlob) {
        //console.log('chunk of real-time data is: ', recordedBlob);
  }
 
  onStop = (recordedBlob) => {
        var {onUpdate} = this.props;
        var reader = new FileReader();
        reader.readAsDataURL(recordedBlob.blob); 
        reader.onloadend = () => {
            var base64data = reader.result; 
            //console.log(base64data)
            onUpdate(base64data)
            this.setState({blob:base64data})               
            //console.log(base64data);
        }
        
        console.log('recordedBlob is: ', recordedBlob);
  }
 
    render() {
        var {blob, record} = this.state;
        var {base64Sound} = this.props;
        blob = base64Sound
        console.log(blob);
        return (
        <div>
            {blob?<audio controls="controls" autobuffer="autobuffer" autoplay="autoplay" style={{width:'100%'}}>
                <source src={blob} />
            </audio>:null}
            <div style={{display:''}}><ReactMic
            record={this.state.record}
            //className="sound-wave"
            onStop={this.onStop}
            //onData={this.onData}
            //strokeColor="#000000"
            //backgroundColor="#FFFF"
            /*mimeType="audio/mp3"*/ /*/></div>
            <div class="btn-group" role="group" aria-label="Basic example">
                {!record?<button type="button" onClick={this.startRecording} class="btn btn-success">{!blob?'Ses Kaydet':'Başka Ses Kaydet'}</button>:
                <button type="button" onClick={this.stopRecording} class="btn btn-danger">Durdur</button>}
                
            </div>
        </div>
        );
    }
}*/



